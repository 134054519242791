import { isNil } from "lodash";

class LocalStorageService {
  /**
   * 添加或修改一个键值对
   * @param key 键名
   * @param value 键值，该值会被转换成 JSON 字符串存储
   */
  setItem(key: string, value: any): void {
    if(value){
      const jsonValue = JSON.stringify(value);
      localStorage.setItem(key, jsonValue);
    }
    
  }

  /**
   * 获取一个键值对
   * @param key 键名
   * @returns 键值，该值是解析后的 JSON 对象
   */
  getItem(key: string): any {
    const jsonValue = localStorage.getItem(key);
    if (isNil(jsonValue) || jsonValue === "undefined") {
      return null; // 如果值为 null，直接返回 null
    }
    try {
      return JSON.parse(jsonValue); // 尝试解析 JSON 字符串
    } catch (err) {
      // 如果解析失败，说明该值不是 JSON 字符串，直接返回该值
      // eslint-disable-next-line no-console
      console.error(`Error parsing storage item "${key}".`, err);
      return null;
    }
  }

  /**
   * 删除一个键值对
   * @param key 键名
   */
  removeItem(key: string): void {
    localStorage.removeItem(key);
  }

  /**
   * 清除所有键值对
   */
  clear(): void {
    localStorage.clear();
  }
}

const storage = new LocalStorageService();
export default storage;
