import assert from './utils/assert';
import createShareButton from './hocs/createShareButton';
import objectToGetParams from './utils/objectToGetParams';

function instapaperLink(url, { title, description }) {
  assert(url, 'instapaper.url');

  return (
    `http://www.instapaper.com/hello2${ 
    objectToGetParams({
      url,
      title,
      description,
    })}`
  );
}

const InstapaperShareButton = createShareButton(
  'Instapaper',
  instapaperLink,
  props => ({
    title: props.title,
    description: props.description,
  }),
  {
    windowWidth: 500,
    windowHeight: 500,
    windowPosition: 'windowCenter',
  },
);

export default InstapaperShareButton;
