import React from "react";

import {
  TwitterShareButton,
  FacebookShareButton,
  VKShareButton,
  OKShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  RedditShareButton,
  EmailShareButton,
  LivejournalShareButton,
  MailruShareButton,
  WorkplaceShareButton,
  LineShareButton,
  WeiboShareButton,
  PocketShareButton,
  InstapaperShareButton,
  HatenaShareButton,
 
  OKIcon,
  TelegramIcon,
  MailruIcon,
  LivejournalIcon,
  WorkplaceIcon,
  LineIcon,
  PocketIcon,
  InstapaperIcon,
  WeiboIcon,
  HatenaIcon,
} from "../react-share/index";
import TwitterIcon from "../react-share/icons/Twitter";
import WhatsappIcon from "../react-share/icons/Whatsapp";
import FacebookIcon from "../react-share/icons/Facebook";
import EmailIcon from "../react-share/icons/Email";
import RedditIcon from "../react-share/icons/Reddit";
import VKIcon from "../react-share/icons/VK";
 
function Share({ title, shareUrl, shareImage, hashtags }) {
  
  return (
    <div className="flex flex-wrap ">
      <TwitterShareButton
        url={shareUrl}
        title={title}
        hashtags={hashtags}
        className="flex w-[60px]"
      >
        <TwitterIcon />
      </TwitterShareButton>

      <WhatsappShareButton url={shareUrl} title={title} separator=":: ">
        <WhatsappIcon />
      </WhatsappShareButton>

      <FacebookShareButton url={shareUrl} quote={title}>
        <FacebookIcon />
      </FacebookShareButton>

      <EmailShareButton url={shareUrl} subject={title} body="body">
        <EmailIcon />
      </EmailShareButton>

      <RedditShareButton
        url={shareUrl}
        title={title}
        windowWidth={660}
        windowHeight={460}
      >
        <RedditIcon />
      </RedditShareButton>

      <VKShareButton
        url={shareUrl}
        image={shareImage}
      >
        <VKIcon />
      </VKShareButton>

      <OKShareButton
        url={shareUrl}
        image={shareImage}
      >
        <OKIcon size={60} round />
      </OKShareButton>


      <TelegramShareButton url={shareUrl} title={title}>
        <TelegramIcon size={60} round />
      </TelegramShareButton>

      <LivejournalShareButton
        url={shareUrl}
        title={title}
        description={shareUrl}
      >
        <LivejournalIcon size={60} round />
      </LivejournalShareButton>

      <MailruShareButton url={shareUrl} title={title}>
        <MailruIcon size={60} round />
      </MailruShareButton>

      <WorkplaceShareButton url={shareUrl} quote={title}>
        <WorkplaceIcon size={60} round />
      </WorkplaceShareButton>

      <LineShareButton url={shareUrl} title={title}>
        <LineIcon size={60} round />
      </LineShareButton>

      <WeiboShareButton
        url={shareUrl}
        title={title}
        image={shareImage}
      >
        <WeiboIcon size={60} round />
      </WeiboShareButton>

      <PocketShareButton url={shareUrl} title={title}>
        <PocketIcon size={60} round />
      </PocketShareButton>

      <InstapaperShareButton url={shareUrl} title={title}>
        <InstapaperIcon size={60} round />
      </InstapaperShareButton>

      <HatenaShareButton
        url={shareUrl}
        title={title}
        windowWidth={660}
        windowHeight={460}
      >
        <HatenaIcon size={60} round />
      </HatenaShareButton>
    </div>
  );
}

export default Share;
