// store.ts
import { create } from "zustand";
import AxiosService from "../api/AxiosService";
import LocalStorageService from "../service/LocalStorageService";
import { normalize } from "../utils/fp";

const STORE = "HOT_STORE";
const GPT_MAP = "GPT_MAP";

export type Store = {
  stores: any[];
  gptMap: any;

  getHotStore: () => void;
};

export const useHotStore = create<Store>((set) => ({
  stores: LocalStorageService.getItem(STORE),
  gptMap: LocalStorageService.getItem(GPT_MAP),

  getHotStore: () =>
    AxiosService.get("/api/get-hot-store").then((res: any) => {
      const gptMap = normalize(res.gptsData);
      LocalStorageService.setItem(STORE, res.stores);
      LocalStorageService.setItem(GPT_MAP, gptMap);

      set({
        stores: res.stores,
        gptMap,
      });
    }),
}));
