// 路由守卫
import { useEffect } from "react";
import Cookies from "js-cookie";
import { TOKEN_KEY } from "../constants/user";
import { useGo } from "./useGo";

export const useRouterGuards = () => {
  const { navigate } = useGo();

  useEffect(() => {
    const token = Cookies.get(TOKEN_KEY);
    if (!token) {
      navigate("/discover");
    }
  }, []);
};
