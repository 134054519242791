import { useEffect } from "react";
import { useUserStore } from "../store/user";
import { useSetUserInfo } from "./useSetUserInfo";

import AxiosService from "../api/AxiosService";

export const useLogin = () => {
  const { setUserInfo } = useSetUserInfo();
  const { setLoginModal } = useUserStore();

  const handleCredentialResponse = (response: any) => {
    // eslint-disable-next-line no-console
    AxiosService.post("/api/google-login", {
      token: response.credential,
    }).then((res: any) => {
      // eslint-disable-next-line no-console
      console.log(res);
      setUserInfo(res?.user, res.token);
      setLoginModal(false);
    });
  };
  useEffect(() => {
    try {
      // @ts-ignore
      if (window.google) {
        // @ts-ignore
        window.google?.accounts?.id?.initialize({
          client_id:
            "540843721407-pef3e03mrogofbfeu60qgla001prnh2l.apps.googleusercontent.com",
          callback: handleCredentialResponse,
        });
        // console.log("google login init");
        // eslint-disable-next-line no-undef
        // @ts-ignore
        // google.accounts.id.prompt();
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  }, []);
};
