import { memo, useMemo } from "react";
import { noEmpty, prop, first } from "../../../utils/fp";
import Share from "../../../components/Share/Share";
import { getCover } from "../../../utils/cdn";
import { getShareLink } from "../../../utils/link";

const Cover = ({ store, gpts }: any) => {
  const cover = useMemo(() => {
    const storeCover = getCover(store);
    if (storeCover) {
      return storeCover;
    }
    if (noEmpty(gpts)) {
      const gpt = first(gpts);
      return gpt?.profile_picture_url;
    }
    return "";
  }, [store]);
  return (
    <div>
      <div className="flex flex-col justify-between   relative  ">
        <img src={cover} alt="" className="w-full object-cover" />
        <div className="p-[20px]">
          <h1 className="text-[4rem] leading-[4.75rem] cursor-pointer   ">
            {store?.name}
          </h1>
          <div className="link mt-4">{prop("description")(store)}</div>
          <div className="flex items-center ">
            <div className="flex items-center ">
              <Share
                shareUrl={getShareLink(`/store/${store.id}`)}
                title={store?.name}
                shareImage={cover}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(Cover);
