import assert from './utils/assert';
import objectToGetParams from './utils/objectToGetParams';
import createShareButton from './hocs/createShareButton';

function mailruLink(url, { title, description, imageUrl }) {
  assert(url, 'mailru.url');

  return (
    `https://connect.mail.ru/share${ 
    objectToGetParams({
      url,
      title,
      description,
      image_url: imageUrl,
    })}`
  );
}

const MailruShareButton = createShareButton(
  'Mailru',
  mailruLink,
  props => ({
    title: props.title,
    description: props.description,
    imageUrl: props.imageUrl,
  }),
  {
    windowWidth: 660,
    windowHeight: 460,
  },
);

export default MailruShareButton;
