import { memo, useState, useCallback } from "react";
import { Modal, Form, Input, Button, notification } from "antd";
import { isFunction } from "../../../utils/fp";
import { useGptStore } from "../../../store/gpt";

type Props = {
  onSubmitSuccess?: (key: any, res?: any) => void;
  callback?: () => void;
  character?: any;
  children?: React.ReactNode;
};

const Gpt = ({ onSubmitSuccess, callback, children }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  const { newGpt } = useGptStore();

  const [notificationCall, contextHolder] = notification.useNotification();

  const showModal = useCallback(
    (e: any) => {
      e.stopPropagation();
      e.preventDefault();
      setIsModalOpen(true);
      if (callback && isFunction(callback)) {
        callback();
      }
    },
    [callback]
  );

  const hideModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const onFinish = useCallback(
    (values: any) => {
      setLoading(true);

      newGpt(values)
        .catch(() => {
          notificationCall.error({
            message: "Sumbit failed",
          });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [newGpt, onSubmitSuccess, notificationCall]
  );

  return (
    <div>
      {contextHolder}
      <div onClick={showModal}>{children}</div>

      <Modal
        title="Submit GPT"
        open={isModalOpen}
        footer={null}
        width={600}
        onCancel={hideModal}
        zIndex={2000}
      >
        <Form
          name="basic"
          form={form}
          layout="vertical"
          style={{ maxWidth: 800 }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            label="GPT URL"
            name="url"
            rules={[
              { required: true, message: "Please enter the URL for GPT." },
            ]}
          >
            <Input
              size="large"
              placeholder="https://chat.openai.com/g/g-xxxx"
            />
          </Form.Item>

          <Button
            className="w-full"
            size="large"
            type="primary"
            htmlType="submit"
            loading={loading}
          >
            {loading
              ? " I'm submitting the GPT, might take a minute or two."
              : "Submit GPT"}
          </Button>
        </Form>
      </Modal>
    </div>
  );
};

export default memo(Gpt);
