import assert from './utils/assert';
import createShareButton from './hocs/createShareButton';

function hatenaLink(url, { title }) {
  assert(url, 'hatena.url');

  return `http://b.hatena.ne.jp/add?mode=confirm&url=${url}&title=${title}`;
}

const HatenaShareButton = createShareButton(
  'Hatena',
  hatenaLink,
  props => ({
    title: props.title,
  }),
  {
    windowWidth: 660,
    windowHeight: 460,
    windowPosition: 'windowCenter',
  },
);

export default HatenaShareButton;
