import { memo, useMemo } from "react";
import { Avatar } from "antd";
import { Link } from "react-router-dom";
import Try from "../Try/index";

import { filter, mapIndexed } from "../../utils/fp";

const Gpts = ({ gpts }: any) => {
  const list = useMemo(() => filter((item: any) => item?.name)(gpts), [gpts]);
  return (
    <ul className="flex flex-col  justify-center   items-center ">
      {mapIndexed((item: any, index: number) => (
        <Link key={index} to={`/gpt/${item.id}`}>
          <li className="flex rounded-lg max-w-[600px] flex-col  mb-[20px]  cover-shadow   px-4  items-center justify-between py-4 cursor-pointer">
            <Avatar
              src={item?.profile_picture_url}
              className="mr-2 w-[148px] h-[148px] cover-shadow"
            />
            <h2 className="title text-[16px] ">{item?.name}</h2>
            <div className="flex flex-1 flex-col justify-center ">
              <div className="block ">{item?.description}</div>
            </div>

            <Try gpt={item} />
          </li>
        </Link>
      ))(list)}
    </ul>
  );
};

export default memo(Gpts);
