// 验证手机号码
export function validatePhoneNumber(phoneNumber: string) {
  const regex = /^1[3-578]\d{9}$/;
  return regex.test(phoneNumber);
}
// 验证验证码
export function validateCode(code: string) {
  const reg = /^\d{6}$/;
  return reg.test(code);
}

// 验证邮箱地址

export function validateEmail(email: string): boolean {
  const regex = /^[\w.-]+@[\d.A-Za-z-]+\.[A-Za-z]{2,6}$/;
  return regex.test(email);
}
