import { useTranslation } from "react-i18next";

import "./App.css";
import { ConfigProvider } from "antd";
import { useEffect } from "react";

import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import moment from "moment";
import Store from "./views/Store/Store";
import StoreDetails from "./views/Store/Details";
import GPT from "./views/GPT/GPT";
import GPTDetails from "./views/GPT/Details";
import Profile from "./views/Profile";

import Layout from "./components/Layout/index";
import { makeHash } from "./utils/hash";
import theme from "./constants/theme";
import "./i18n/config";
import NoMatch from "./views/NoMatch/NoMatch";
import SignUp from "./views/SignUp/Sign";
import LocalStorageService from "./service/LocalStorageService";
import { useGetMeta } from "./hooks/useGetMeta";
import Discover from "./views/Discover/index";
import Latest from "./views/Discover/Latest";
import Top from "./views/Top/Top";

import Privacy from "./views/Legal/Privacy";
import Terms from "./views/Legal/Terms";

//  如果是白名单中的路由，就不需要登录，否则就需要登录

const getStoreName = () => {
  const hostname = window?.location?.hostname;
  const store = hostname?.split(".")[0];
  if (store === "gpt-store" || store === "localhost") {
    return null;
  }
  return `/store/${store}`;
};

function App() {
  const { i18n } = useTranslation();

  useEffect(() => {
    makeHash();
  }, []);

  useEffect(() => {
    const lang = LocalStorageService.getItem("lang") || "en";
    i18n.changeLanguage(lang);
    moment.locale(lang);
  }, [i18n]);

  const { getMeta } = useGetMeta();

  useEffect(() => {
    getMeta();
  }, [getMeta]);

  const defaultRoute = getStoreName() || "/top";

  return (
    <div id="app" className="App">
      <ConfigProvider theme={theme}>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Navigate to={defaultRoute} />} />
            <Route path="/discover" element={<Discover />} />
            <Route path="/latest" element={<Latest />} />
            <Route path="/top" element={<Top />} />
            <Route path="/top/:id" element={<Top />} />

            <Route path="/submit-gpt" element={<GPT />} />
            <Route path="/submit-gpt/:id" element={<GPT />} />

            <Route path="store" element={<Store />} />

            <Route path="profile" element={<Profile />} />
          </Route>
          <Route path="gpt/:id" element={<GPTDetails />} />
          <Route path="store/:id" element={<StoreDetails />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="*" element={<NoMatch />} />
        </Routes>
      </ConfigProvider>
    </div>
  );
}

export default function Provider() {
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
}
