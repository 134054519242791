// 获取元数据
import { useCallback } from "react";
import Cookies from "js-cookie";
import { useGptStore } from "../store/gpt";
import { useStore } from "../store/store";
import { useTopGptStore } from "../store/topGpt";
import { useHotStore } from "../store/hot";
import { TOKEN_KEY } from "../constants/user";

export const useGetMeta = () => {
  const { getMyGpt } = useGptStore();
  const { getMyStore } = useStore();
  const { getHotStore } = useHotStore();
  const { getTopGpt } = useTopGptStore();

  const getMeta = useCallback(() => {
    getHotStore();
    getTopGpt('All');
    const token = Cookies.get(TOKEN_KEY);
    if (token) {
      getMyGpt();
      getMyStore();
    }
  }, [getMyGpt]);

  return {
    getMeta,
  };
};
