import { memo, useCallback } from "react";
import { Avatar, Button } from "antd";
import { prop, noEmpty } from "../../../utils/fp";
import styles from "./style.module.scss";
import Share from "../../../components/Share/Share";
import { useGo } from "../../../hooks/useGo";
import {
  stopPropagation,
  getGptLink,
  getGptShareLink,
  getShareDesc,
} from "../../../utils/link";
import { track } from "../../../hooks/useTrack";
import OperateBar from "./OperateBar";

const Cover = ({ gpt, setActive }: any) => {
  const { go } = useGo();

  const onGo = useCallback(() => {
    track("use", gpt?.id);
  }, [gpt, go]);

  return (
    <div>
      <OperateBar gpt={gpt} setActive={setActive} />

      <div className="flex justify-between   relative px-[16px] md:px-[0] ">
        <div className="mt-[48px]">
          <h1 className="text-[2rem] leading-[2.35rem] md:text-[4rem] md:leading-[4.75rem] ">
            {gpt?.name}
          </h1>
          {noEmpty(prop("gizmo.author.link_to")(gpt)) ? (
            <a
              href={prop("gizmo.author.link_to")(gpt)}
              target="_blanck"
              className="link mt-4"
            >
              By {prop("gizmo.author.display_name")(gpt)}
            </a>
          ) : (
            <div className="mt-4">
              By {prop("gizmo.author.display_name")(gpt)}
            </div>
          )}

          <div className="flex mt-4 mb-4 py-3  ">
            {prop("reviews")(gpt) && (
              <div className="flex flex-col pr-[16px] items-center min-w-[96px]">
                <div className="meta-tag">{prop("reviews")(gpt)}</div>{" "}
                <div className="meta-label ">Views</div>
              </div>
            )}
            {prop("use")(gpt) && (
              <div
                className={`${styles.metaData} relative flex  flex-col items-center min-w-[96px] px-[16px] `}
              >
                <div className="meta-tag">{prop("use")(gpt)}</div>
                <div className="meta-label ">Uses</div>{" "}
              </div>
            )}
          </div>
          <div className="flex items-center ">
            <a
              href={getGptLink(gpt)}
              onClick={stopPropagation}
              target="_blanck"
            >
              <Button
                type="primary"
                onClick={onGo}
                className="rounded-[8px] mr-2 md:w-[140px] md:w-[200px] text-[16px] h-[44px] px-[16px] "
              >
                Try Now
              </Button>
            </a>
            <div className="ml-4 flex items-center ">
              <Share
                title={`${prop("name")(gpt)} | ${getShareDesc(
                  prop("description")(gpt)
                )}`}
                shareImage={gpt?.profile_picture_url}
                shareUrl={getGptShareLink(gpt)}
              />
            </div>
          </div>
        </div>
        <Avatar
          className="absolute right-[16px] md:right-0 top-[48px]   w-[80px]  h-[80px]  md:w-[240px]  md:h-[240px] cover-shadow"
          src={gpt?.profile_picture_url}
        />
      </div>
    </div>
  );
};

export default memo(Cover);
