import { useCallback } from "react";
import { Divider, Button } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
// import { LanguageSetting } from "../../components/Header/LanguageSetting";
import { useUserStore } from "../../store/user";
import LocalStorageService from "../../service/LocalStorageService";

import { TOKEN_KEY, USER_INFO } from "../../constants/user";
import BaseInfo from "./components/BaseInfo";
import { useRouterGuards } from "../../hooks/useRouterGuards";

const Profile = () => {
  useRouterGuards();

  const { t } = useTranslation();
  const navigate = useNavigate();

  const { setUser } = useUserStore();

  const logout = useCallback(() => {
    setUser(null);
    Cookies.remove(TOKEN_KEY);
    LocalStorageService.removeItem(USER_INFO);
    navigate("/discover");
  }, [setUser, navigate]);

  return (
    <div className=" w-full max-w-[1288px] m-auto p-4">
      <BaseInfo />

      <Divider />

      <Button className="" onClick={() => logout()}>
        {t("logout")}
      </Button>
    </div>
  );
};

export default Profile;
