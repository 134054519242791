import { memo } from "react";
import { noEmpty, uniqBy, flow, mapIndexed } from "../../../utils/fp";
import IframeEmbed from "./Iframe";

const Description = ({ gpt }: any) => (
  <div className="p-[20px]">
    <div className="max-w-[800px] px-[16px] md:px-[0] ">
      <section className="flex flex-col pt-[20px] pb-[24px] ">
        <h2 className="h2  mb-[20px]">Description</h2>
        <div className="flex-1 desc whitespace-pre-wrap">
          {gpt?.description || "-"}
        </div>
      </section>
      {noEmpty(gpt?.prompt_starters) && (
        <section className="flex flex-col  pt-[20px] pb-[24px]">
          <h2 className="h2 mb-[20px]">Prompt Starters</h2>
          <ul className="flex-1">
            {mapIndexed((item: any, index: number) => (
              <div
                key={index}
                className="py-2 px-3 mb-2 desc  rounded-xl border"
              >
                {item}
              </div>
            ))(gpt?.prompt_starters)}
          </ul>
        </section>
      )}
      <section className="flex flex-col  pt-[20px] pb-[24px]">
        <h2 className="h2 mb-[20px]">Showcase</h2>

        {noEmpty(gpt?.album) && (
          <div>
            {mapIndexed((item: any) => <img src={item.url} alt="" />)(
              gpt?.album
            )}
          </div>
        )}
      </section>
      {noEmpty(gpt?.case) && (
        <section className="flex flex-col  pt-[20px] pb-[24px]">
          <h2 className="h2 mb-[20px]">Conversation Case</h2>
          <IframeEmbed src={gpt?.case} />
        </section>
      )}
      <section className="flex flex-col  pt-[20px] pb-[24px]">
        <h2 className="h2 mb-[20px]">Welcome Message</h2>
        <div className="flex-1 desc whitespace-pre-wrap">
          {gpt?.welcome_message}
        </div>
      </section>

      {noEmpty(gpt?.tools) && (
        <section className="flex flex-col  pt-[20px] pb-[24px]">
          <h2 className="h2 mb-[20px]">Tools</h2>
          <div className="flex-1 desc whitespace-pre-wrap">
            {flow(
              uniqBy("type"),
              mapIndexed(({ type }: any, index: number) => (
                <div key={index}>{type}</div>
              ))
            )(gpt?.tools)}
          </div>
        </section>
      )}
    </div>
  </div>
);

export default memo(Description);
