// store.ts
import { create } from "zustand";
import AxiosService from "../api/AxiosService";
import LocalStorageService from "../service/LocalStorageService";

const LATEST_GPT = "LATEST_GPT";

export type Store = {
  gpts: any[];

  getLatestGpt: () => void;
};

export const useLatestGptStore = create<Store>((set) => ({
  gpts: LocalStorageService.getItem(LATEST_GPT),

  getLatestGpt: () =>
    AxiosService.get("/api/get-latest-gpt").then((res: any) => {
      LocalStorageService.setItem(LATEST_GPT, res);

      set({
        gpts: res,
      });
    }),
}));
