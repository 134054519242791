import React, { useCallback } from 'react';
import cx from 'classcat';

const isPromise = (obj) =>
  !!obj && (typeof obj === 'object' || typeof obj === 'function') && typeof obj.then === 'function';

const getBoxPositionOnWindowCenter = (width, height) => ({
  left: window.outerWidth / 2 + (window.screenX || window.screenLeft || 0) - width / 2,
  top: window.outerHeight / 2 + (window.screenY || window.screenTop || 0) - height / 2,
});

const getBoxPositionOnScreenCenter = (width, height) => ({
  top: (window.screen.height - height) / 2,
  left: (window.screen.width - width) / 2,
});

function windowOpen(url, { height, width, ...configRest }, onClose) {
  const config = {
    height,
    width,
    location: 'no',
    toolbar: 'no',
    status: 'no',
    directories: 'no',
    menubar: 'no',
    scrollbars: 'yes',
    resizable: 'no',
    centerscreen: 'yes',
    chrome: 'yes',
    ...configRest,
  };

  const shareDialog = window.open(
    url,
    '',
    Object.keys(config)
      .map(key => `${key}=${config[key]}`)
      .join(', '),
  );

  if (onClose) {
    const interval = window.setInterval(() => {
      try {
        if (shareDialog === null || shareDialog.closed) {
          window.clearInterval(interval);
          onClose(shareDialog);
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    }, 1000);
  }

  return shareDialog;
}

const ShareButton = ({
  children,
  className,
  disabled,
  forwardedRef,
  style,
  onShareWindowClose,
  windowHeight = 400,
  windowPosition = 'windowCenter',
  windowWidth = 550,
  beforeOnClick,
  networkLink,
  onClick,
  url,
  openShareDialogOnClick = true,
  opts,
  networkName,
  ...rest
}) => {
  const openShareDialog = useCallback((link) => {
    const windowConfig = {
      height: windowHeight,
      width: windowWidth,
      ...(windowPosition === 'windowCenter'
        ? getBoxPositionOnWindowCenter(windowWidth, windowHeight)
        : getBoxPositionOnScreenCenter(windowWidth, windowHeight)),
    };

    windowOpen(link, windowConfig, onShareWindowClose);
  }, [onShareWindowClose, windowHeight, windowPosition, windowWidth]);

  const handleClick = useCallback(async (event) => {
    const link = networkLink(url, opts);

    if (disabled) {
      return;
    }

    event.preventDefault();

    if (beforeOnClick) {
      const returnVal = beforeOnClick();

      if (isPromise(returnVal)) {
        await returnVal;
      }
    }

    if (openShareDialogOnClick) {
      openShareDialog(link);
    }

    if (onClick) {
      onClick(event, link);
    }
  }, [beforeOnClick, disabled, networkLink, onClick, openShareDialog, openShareDialogOnClick, opts, url]);

  const newClassName = cx(
    'flex flex-col m-4  w-[60px] items-center justify-center',
    {
      disabled: !!disabled,
    },
    className,
  );


  return (
    <button
      {...rest}
      type='button'
      aria-label={rest['aria-label'] || networkName}
      className={newClassName}
      onClick={handleClick}
      ref={forwardedRef}
  
    >
      {children}
      <div className='mt-2'>
      {networkName}
      </div>
    </button>
  );
};

export default ShareButton;
