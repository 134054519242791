import { includes, prop } from "./fp";

export const getUrl = (key: string) => (obj: any) => {
  const url = prop(key)(obj);
  if (includes("http")(url)) {
    return url;
  }
  if (url) {
    return `https://chapose.azureedge.net${url}`;
  }

  return null;
};

export const getCover = getUrl("cover");