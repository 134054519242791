import { memo } from "react";

const Copyright = () => (
      <footer className="fixed bottom-0 w-full  flex  justify-center   p-4     text-sm">
        <div>
           
          <a href="/terms" className="ml-1 text-gray-500">
            Terms Of Service
          </a>
          <span className="mx-1 text-gray-500">|</span>
          <a href="/privacy" className="ml-1 text-gray-500">
            Privacy policy
          </a>
            
        </div>
      </footer>
    )
  
  export default memo(Copyright);
