import { useEffect } from "react";
import { useLatestGptStore } from "../../store/latestGpt";
import { useSEO } from "../../hooks/useSeo";
import { noEmpty } from "../../utils/fp";
import Gpts from "../../components/Gpts";
import Loading from "../../components/Loading";
import { getShareLink } from "../../utils/link";
import Share from "../../components/Share/Share";

function Latest() {
  const { gpts, getLatestGpt } = useLatestGptStore();
  const setSeo = useSEO();
  useEffect(() => {
    setSeo({
      title: "Check Out the Coolest New GPTs – They're Changing the Game!",
      description:
        "Ready for some awesome new tools? Dive into our latest GPTs at GPT-Store and see how they're making everything easier and way more fun. From boosting your work efficiency to spicing up your daily tasks, these GPTs are a total game-changer for tech lovers and everyday users alike",
      keywords: "GPT, GPT-4, GPT-Store,   New GPTs, GPTS",
      ogUrl: `/latest`,
    });
    getLatestGpt();
  }, [getLatestGpt]);

  return (
    <div className="w-full max-w-[1288px] m-auto ">
      {noEmpty(gpts) ? (
        <div>
          <h1 className=" flex justify-between item-center text-[2rem] leading-[2.35rem]  p-[20px] ">
            New GPTs
            <Share title="New GPTs" shareUrl={getShareLink("/latest")} />
          </h1>
          <Gpts gpts={gpts} />
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
}

export default Latest;
