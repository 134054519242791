import { Spin } from "antd";

export default function Loading() {
  return (
    <div className="w-full h-full   mt-10  flex  justify-center items-center   ">
      <Spin size="large" className="text-center " />
      <div className="ml-4 font-[20px] font-medium text-gray-500 ">
        {" "}
        Loading ...
      </div>
    </div>
  );
}
