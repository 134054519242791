import jsonp from 'jsonp';

import objectToGetParams from './utils/objectToGetParams';
import createShareCount from './hocs/createShareCount';

function getHatenaShareCount(shareUrl, callback) {
  const url = 'https://bookmark.hatenaapis.com/count/entry';

  jsonp(
    url +
      objectToGetParams({
        url: shareUrl,
      }),
    (err, data) => {
      callback(data || undefined);
    },
  );
}

export default createShareCount(getHatenaShareCount);
