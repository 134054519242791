import { useEffect } from 'react';

export const useDocumentTitle = (title: string) => {
  useEffect(() => {
    // 保存旧的title
    const prevTitle = document.title;
    // 设置新的title
    if(title){
        document.title = title;
    } 

    // 当组件卸载时，恢复旧的title
    return () => {
      document.title = prevTitle;
    };
  }, [title]); // 当title变化时更新
};

 