import { memo } from "react";
import { Link } from "react-router-dom";

const Copyright = () => (
  <footer className="w-full text-gray-500  flex  justify-center   p-4  mt-[36px]   ">
    <div className="max-w-[1288px] w-full m-auto border-t py-[26px]">
      <Link className="mr-6"  to="/terms">Terms Of Service</Link>
      <Link to="/privacy"> Privacy policy</Link>   
 
    </div>
  </footer>
);

export default memo(Copyright);
