import FingerprintJS from "@fingerprintjs/fingerprintjs";
import LocalStorageService from "../service/LocalStorageService";


const V = "V";

const S= "S";

function generateRandomBase64(length: number) {
    // 生成随机字节数组
    const randomBytes: any = new Uint8Array(Math.ceil(length * 3 / 4));
    window.crypto.getRandomValues(randomBytes);

    // 转换为Base64并截取所需长度
    const base64String = window.btoa(String.fromCharCode.apply(null, randomBytes));
    return base64String.substring(0, length);
}
export const getHash = () => LocalStorageService.getItem(S);
export const getVid = () => LocalStorageService.getItem(V);

export const makeHash = () => {
  const setFp = async () => {
    if(getHash()){
        return;
    }
    const fp = await FingerprintJS.load();

    const { visitorId } = await fp.get();
    const hash = btoa(visitorId);
    LocalStorageService.setItem(V,visitorId);
    LocalStorageService.setItem(S,generateRandomBase64(7) +hash);
  };
  setFp();
};

