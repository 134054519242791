import React, { useState, useCallback, useMemo } from "react";
import { notification, Input, Button } from "antd";
import { LeftOutlined } from "@ant-design/icons";

import AxiosService from "../../api/AxiosService";
import { prop, trim, size } from "../../utils/fp";
import { validateEmail } from "./utils";
import { useSetUserInfo } from "../../hooks/useSetUserInfo";
import Init from "../../components/Profile/Init";
import { useGo } from "../../hooks/useGo";
import Copyright from "./components/Footer";

const Sign: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<any>("");
  const [captchaed, setCaptchaed] = useState<any>(false);
  const [isRegister, setIsRegister] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [notificationCall, contextHolder] = notification.useNotification();

  const { setUserInfo } = useSetUserInfo();
  const { navigate } = useGo();

  const onValidateHuman = useCallback(() => {
    if (!validateEmail(email)) {
      notificationCall.error({
        message: "请输入正确的手机号",
      });
      return;
    }
    setCaptchaed(true);
  }, [email, notificationCall]);

  const onChangeEmail = useCallback((e: any) => {
    setEmail(trim(e.target.value));
  }, []);

  const onChangePassword = useCallback((e: any) => {
    setPassword(trim(e.target.value));
  }, []);

  const goBack = useCallback(() => {
    if (captchaed) {
      setCaptchaed(false);
      setPassword("");
      return;
    } 
    navigate("/discover");
   
  }, []);

  const onSignUp = useCallback(() => {
    if (size(password) < 6) {
      notificationCall.error({
        message: "Your password must contain: At least 6 characters",
      });
      return;
    }
    setLoading(true);
    AxiosService.post("/api/register", {
      email: `${email}`,
      password,
    })
      .then((res: any) => {
        setUserInfo(res?.user, res?.token);
        if (res?.user?.nickname) {
          navigate("/discover");
        } else {
          setIsRegister(true);
        }
      })
      .catch((err) => {
        notificationCall.error({
          message: prop("error")(err),
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [password, notificationCall, email, setUserInfo, navigate]);

  // const onResend = useCallback(() => {
  //   onSendCaptcha(captchaRes);
  // }, [onSendCaptcha, captchaRes]);

  const Form = useMemo(() => {
    if (!captchaed) {
      return (
        <div className="p-8 rounded  ">
          <Input
            className="mb-6 text-[16px] w-full h-[48px] "
            value={email}
            onChange={onChangeEmail}
            placeholder="Email Address"
          />
          <Button
            type="primary"
            loading={loading}
            className="mb-4 text-[16px] w-full h-[48px] "
            onClick={onValidateHuman}
          >
            Continue
          </Button>
        </div>
      );
    }
    if (isRegister) {
      return (
        <div className="p-8 rounded  ">
          <Init />
        </div>
      );
    }

    return (
      <div className="p-8 rounded  ">
        <Input.Password
          className="mb-6 text-[16px] w-full h-[48px] "
          value={password}
          onChange={onChangePassword}
          placeholder="Password"
        />

        <Button
          type="primary"
          loading={loading}
          className="mb-4 text-[16px] w-full h-[48px] "
          onClick={onSignUp}
        >
          Continue
        </Button>
      </div>
    );
  }, [
    password,
    captchaed,
    onChangePassword,
    onChangeEmail,
    onValidateHuman,
    email,
    onSignUp,
    isRegister,
    loading,
  ]);

  return (
    <div className={`w-full flex justify-center items-center  h-[100vh]  p-8 `}>
      <LeftOutlined
        className="absolute top-4 left-4   text-[24px] cursor-pointer"
        onClick={goBack}
      />

      {contextHolder}
      <div className="w-[440px]  ">
        {!captchaed && (
          <div>
            <div className="text-[26px] md:text-[40px] flex justify-center  text-brand-green font-logo font-bold  ">
              Create your account
            </div>
          </div>
        )}
        {Form}
      </div>
      <Copyright />
    </div>
  );
};

export default Sign;
