import { memo, useMemo } from "react";
import { Avatar } from "antd";
import { Link } from "react-router-dom";
import Try from "../Try/index";
 

import { filter, mapIndexed } from "../../utils/fp";

const Gpts = ({ gpts }: any) => {
  const list = useMemo(() => filter((item: any) => item?.name)(gpts), [gpts]);
  return  (

  <ul className="flex-1 desc  ">
    {mapIndexed((item: any, index: number) => (
      <Link to={`/gpt/${item.id}`}>
        <li className="hover:bg-gray-100 flex  border-b border-gray-100 px-4  items-center justify-between py-4 cursor-pointer">
          <div className="flex items-center ">
            <div className="flex w-[32px] mr-2 text-[16px] items-center justify-center  ">
              {Number(index) + 1}
            </div>
            <Avatar
              src={item?.profile_picture_url}
              className="mr-2 w-[48px] h-[48px] cover-shadow"
            />
            <div className="flex flex-1 flex-col justify-center ">
              <div className="title text-[16px] ">{item?.name}</div>
              <div className="label hidden md:block ">{item?.description}</div>
            </div>
          </div>
          <Try gpt={item} />
        </li>
      </Link>
    ))(list)}
  </ul>
)};

export default memo(Gpts);
