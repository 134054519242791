import type { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import axios from "axios";
import Cookies from "js-cookie";
import { prop } from "../utils/fp";
import { TOKEN_KEY } from "../constants/user";

class AxiosService {
  // AxiosInstance 是一个类型，它表示axios实例
  private instance: AxiosInstance;

  private baseUrl: string = process.env.REACT_APP_API_ROOT || "";

  constructor() {
    // 通过 axios.create() 创建一个新的axios实例
    this.instance = axios.create();
    // 拦截器是axios提供的一种中间件机制，允许你在请求或响应被 then 或 catch 处理前先对它们进行修改
    this.instance.interceptors.request.use(this.handleRequest);
    this.instance.interceptors.response.use(
      this.handleResponse,
      this.handleError,
    );
  }

  private handleRequest(config: AxiosRequestConfig): any {
    // 在此处添加请求拦截处理逻辑，例如添加请求头等
    // 如果你的处理逻辑是异步的，你应该返回一个 Promise，如下：
    // return new Promise((resolve, reject) => {
    //   // 异步操作
    //   resolve(config);
    // });
    // 如果你的处理逻辑是同步的，你可以直接返回 config
    const token = Cookies.get(TOKEN_KEY);
    if (config.headers) {
      config.headers.Authorization = `Bearer ${  token}`;
    } else {
      config.headers = {
        Authorization: `Bearer ${  token}`,
      };
    }

    return config;
  }

  private handleResponse(response: AxiosResponse): AxiosResponse {
    // 响应拦截器可以用来处理响应数据
    // 例如，你可以在此处对响应的格式进行修改或对特定错误代码进行处理
    // console.log(response)
    return response;
  }

  private handleError(error: any): Promise<any> {
    // 这是一个专门用来处理错误的拦截器
    // 你可以在此处对错误进行一些通用处理，例如记录错误日志等
    // console.log(error)
    return Promise.reject(prop("response.data")(error));
  }

  public async get<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
    // 这是一个公共的GET请求方法
    // 你可以通过这个方法发起GET请求，并自动处理响应，使得你可以直接得到你想要的数据
    const response = await this.instance.get<T>(this.baseUrl + url, config);
    return response?.data;
  }

  public getUplodUrl(): string {
    return `${this.baseUrl}/api/upload?token=${Cookies.get(TOKEN_KEY)}`;
  }

  public async post<T>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig,
  ): Promise<T> {
    // 这是一个公共的POST请求方法
    // 你可以通过这个方法发起POST请求，并自动处理响应，使得你可以直接得到你想要的数据
    const response = await this.instance.post<T>(
      this.baseUrl + url,
      data,
      config,
    );
    return response?.data;
  }
}
// 使用 "export default new AxiosService()"，你可以在其它文件中直接导入并使用这个已经创建好的axios实例
const instance = new AxiosService();

export default instance;
