// store.ts
// 引入依赖
import { create } from "zustand";
import type { User } from "../interface/User";
import { USER_INFO } from "../constants/user";
import LocalStorageService from "../service/LocalStorageService";
// 定义状态存储类型
export type UserStore = {
  user: User | null; // 用户信息
  loginModal: boolean; // 是否显示登录弹窗
  setUser: (user: User | null) => void; // 设置用户信息的方法
  removeUser: () => void; // 移除用户信息的方法
  setLoginModal: (loginModal: boolean) => void; // 设置是否显示登录弹窗的方法
};
// 创建状态存储
export const useUserStore = create<UserStore>((set) => ({
  user: LocalStorageService.getItem(USER_INFO), // 使用本地存储中的用户信息初始化用户信息状态
  setUser: (user) => set({ user }), // 创建设置用户信息的方法
  loginModal: false, // 初始化是否显示登录弹窗状态, 默认不显示
  removeUser: () => set({ user: null }), // 创建移除用户信息的方法
  setLoginModal:(loginModal: boolean) => set({ loginModal })
}));
