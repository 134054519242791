import assert from './utils/assert';
import objectToGetParams from './utils/objectToGetParams';
import createShareButton from './hocs/createShareButton';

function linkedinLink(url, { title, summary, source }) {
  assert(url, 'linkedin.url');

  return (
    `https://linkedin.com/shareArticle${ 
    objectToGetParams({ url, mini: 'true', title, summary, source })}`
  );
}

const LinkedinShareButton = createShareButton(
  'linkedin',
  linkedinLink,
  ({ title, summary, source }) => ({ title, summary, source }),
  {
    windowWidth: 750,
    windowHeight: 600,
  },
);

export default LinkedinShareButton;
