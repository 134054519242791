import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { getHash, getVid } from "../utils/hash";
import AxiosService from "../api/AxiosService";
import { noEmpty } from "../utils/fp";

export const track = (type: string, id: string | undefined) => {
  const hash = getHash();
  const vid = getVid();
  if (noEmpty(hash) && noEmpty(id) && noEmpty(type)) {
    AxiosService.post("/api/track", {
      id,
      type,
      data: hash,
      vid,
    });
  };
};

export const useTrack = (type: string) => {
  // 使用解构，以获得 id。
  const { id } = useParams<{ id: string }>();
  useEffect(() => {
    setTimeout(() => {
        track(type, id);
    }, 100);
  }, [id]); // 当title变化时更新
};
