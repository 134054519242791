// store.ts
import { create } from "zustand";
import AxiosService from "../api/AxiosService";
import LocalStorageService from "../service/LocalStorageService";
import { normalize, omit, filter, compact} from "../utils/fp";

const STORE = "STORE";

export type Store = {
  store: any[];
  storeMap: any;
  active: any;
  setActive: (store: any) => void;
  getMyStore: () => void;
  newStore: (meta: any) => any;
  toStore: (gpt: any, store: any) => any;
  refreshStore: (store: any) => void;
  removeStore: (id: string) => void;
};

export const useStore = create<Store>((set, get) => ({
  store: LocalStorageService.getItem(STORE)||[],
  storeMap: normalize(LocalStorageService.getItem(STORE))||{},
  active: {},
  setActive: (store: any) => {
    set({
      active: store,
    });
  },
  getMyStore: () =>
    AxiosService.get("/api/get-my-stores").then((res: any) => {
      LocalStorageService.setItem(STORE, res);
      set({
        store: res,
        storeMap: normalize(res),
      });
    }),
  newStore: (meta: any) =>
    AxiosService.post("/api/new-store", meta).then((store: any) => {
      const { store: oldStore, storeMap: oldStoreMap } = get();
      const newstore = compact([...oldStore]);
      const newStoreMap = { ...oldStoreMap };
      newStoreMap[store.id] = store;
      newstore.unshift(store);
      LocalStorageService.setItem(STORE, store);
      set({
        store: newstore,
        storeMap: newStoreMap,
      });
      return store;
    }),
  toStore: (gpt: any, store: any) => {
    const { gpts = [] } = store;
    const { store: oldstore, storeMap: oldstoreMap } = get();
    const { id } = gpt;
    let newGpts = [...gpts];
    if (gpts.includes(id)) {
      newGpts = filter((item: any) => item !== id)(gpts);
    } else {
      newGpts.push(id);
    }

    const newStore = {
      ...store,
      gpts: newGpts,
    };
    const index = oldstore.findIndex((item) => item.id === store.id);
    oldstoreMap[store.id] = newStore;
    oldstore[index] = newStore;
    set({ store: oldstore, storeMap: oldstoreMap, active: newStore });

    return AxiosService.post("/api/update-store", newStore);
  },
  refreshStore: (store) => {
    /* 将传入的store更新到store和storeMap 中 */
    const { store: oldstore, storeMap: oldstoreMap } = get();
    const newstore = [...oldstore];
    const newstoreMap = { ...oldstoreMap };
    const index = newstore.findIndex((item) => item.id === store.id);
    if (index !== -1) {
      newstore[index] = store;
      newstoreMap[store.id] = store;
    } else {
      newstore.unshift(store);
      newstoreMap[store.id] = store;
    }
    LocalStorageService.setItem(STORE, newstore);
    set({ store: newstore, storeMap: newstoreMap });
  },
  removeStore: (id: string) => {
    /* 将传入的store更新到store和storeMap 中 */
    const { store, storeMap } = get();

    set({
      store: filter((item: any) => item.id !== id)(store),
      storeMap: omit(id)(storeMap),
    });
  },
}));
