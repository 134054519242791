import assert from './utils/assert';
import objectToGetParams from './utils/objectToGetParams';
import createShareButton from './hocs/createShareButton';

function okLink(url, { title, description, image }) {
  assert(url, 'ok.url');

  return (
    `https://connect.ok.ru/offer${ 
    objectToGetParams({
      url,
      title,
      description,
      imageUrl: image,
    })}`
  );
}

const OKShareButton = createShareButton(
  'OK',
  okLink,
  props => ({
    title: props.title,
    description: props.description,
    image: props.image,
  }),
  {
    windowWidth: 588,
    windowHeight: 480,
    windowPosition: 'screenCenter',
  },
);

export default OKShareButton;
