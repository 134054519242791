import { Avatar } from "antd";
import { memo } from "react";
import { Link } from "react-router-dom";
import Try from "../../../../components/Try/index";

import style from "./style.module.scss";

function Card({ gpt }: { gpt: any }) {
 

  return (
    <Link
      key={gpt?.id}
      to={(`/gpt/${gpt?.id}`)}
      title={gpt?.name} 
      className="p-[16px] cursor-pointer flex relative hover:bg-gray-100  group w-[360px]  rounded-xl"
    >
   
      <Avatar
        className="w-[90px] h-[90px] mr-2 cover-shadow "
        src={gpt?.profile_picture_url}
      />
      <div className="flex-1 flex flex-col justify-between ">
        <div className="mb-1 w-full flex justify-between flex-1 ">
          {gpt?.name} 
          <div className="hidden group-hover:flex"><Try gpt={gpt} /></div>
        </div>
        <div className={`desc ${style.truncate}`}>
          {gpt?.description}
        </div>
      </div>
    </Link>
  );
}

export default memo(Card);
