import { memo, useState, useCallback, useMemo } from "react";
import { Modal, Form, Input, Button, notification } from "antd";
import { isFunction } from "../../../utils/fp";
import { useGptStore } from "../../../store/gpt";
import AxiosService from "../../../api/AxiosService";

type Props = {
  onSubmitSuccess?: (key: any, res?: any) => void;
  callback?: () => void;
  character?: any;
  gpt?: any;
};

const Gpt = ({ onSubmitSuccess, callback, gpt }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  const { refreshGpt } = useGptStore();

  const [notificationCall, contextHolder] = notification.useNotification();

  const showModal = useCallback(
    (e: any) => {
      e.stopPropagation();
      e.preventDefault();
      setIsModalOpen(true);
      if (callback && isFunction(callback)) {
        callback();
      }
    },
    [callback]
  );

  const hideModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const onFinish = useCallback(
    (values: any) => {
      setLoading(true);

      AxiosService.post("/api/update-gpt", {
        ...gpt,
        case: values.url,
      })
        .then((res) => {
          refreshGpt(res);
          notificationCall.success({
            message: "Sumbit success",
          });
          hideModal();
        })
        .catch(() => {
          notificationCall.error({
            message: "Sumbit failed",
          });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [refreshGpt, onSubmitSuccess, notificationCall, gpt]
  );

  const label = useMemo(() => {
    if (gpt?.case) {
      return "Update";
    }

    return "Add";
  }, [gpt]);

  return (
    <div>
      {contextHolder}
      <div onClick={showModal}>
        <Button size="large">{label} Case</Button>
      </div>

      <Modal
        title={`${label} Conversation Case `}
        open={isModalOpen}
        footer={null}
        width={600}
        onCancel={hideModal}
        zIndex={2000}
      >
        <Form
          name="basic"
          form={form}
          layout="vertical"
          style={{ maxWidth: 800 }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            label="Share link from ChatGPT"
            name="url"
            rules={[
              {
                required: true,
                message: "Please enter the URL for Share link .",
              },
            ]}
          >
            <Input
              size="large"
              placeholder="https://chat.openai.com/share/xxxx"
            />
          </Form.Item>

          <Button
            className="w-full"
            size="large"
            type="primary"
            htmlType="submit"
            loading={loading}
          >
            Submit
          </Button>

          <div className="my-4">
            <a
              href="https://help.openai.com/en/articles/7925741-chatgpt-shared-links-faq"
              target="_blank"
              rel="noreferrer"
            >
              How to get the share link?
            </a>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default memo(Gpt);
