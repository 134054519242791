/*
 * @Author: ykh 384037191@qq.com
 * @Date: 2022-11-08 20:35:00
 * @LastEditors: ykh 384037191@qq.com
 * @LastEditTime: 2022-11-27 20:43:05
 * @FilePath: /plk_web_enterprise/src/utils/fp.ts
 * @Description:  lodash 的函数式编程库（lodash/fp）
 */
// lodash fp 函数的使用文档https://github.com/lodash/lodash/wiki/FP-Guide

import fp from 'lodash/fp';

// 声明对象的类型


interface IObject {
    [key: string]: any;
}

enum IBoolean {
  Y = 'Y', // 后端定义的true
  N = 'N' // 后端定义的false
}

// 取值

export const {get} = fp; // 获取对象属性的值
export const {prop} = fp; // 获取对象属性的值
export const {propEq} = fp; // 判断对象的属性值是否等于指定的值
export const {first} = fp; // 获取数组的第一个元素
export const {pick} = fp; // 从对象中挑选指定的属性，返回一个新对象
export const {nth} = fp; // 获取数组中指定位置的元素
export const {values} = fp; // 获取对象的所有属性值，返回一个数组
export const {last} = fp; // 获取数组的最后一个元素
export const {min} = fp; // 获取数组中的最小值
export const {max} = fp; // 获取数组中的最大值
 
 

// 计算

export const {toNumber} = fp;
export const {divide} = fp;
 
/**
 * @description: 根据key值获取对象的中对应键的value，不支持key中有嵌套的情况，例如fastProp('a')({a:1})
 * @param {string} key
 * @return {*}
 */
export const fastProp = (key: string | number) => (object: IObject) =>
  object && object[key];

/**
 * @description: 根据key值获取对象的中对应键的value，支持key有嵌套的情况，例如getProp('a.b')({a:{b:1}})
 * @param {string} key
 * @return {*}
 */
export const getProp = (key: string) => (object: IObject) => {
  const keysArr = key.split('.');
  let index = 0;
  const { length } = keysArr;

  while (object != null && index < length) {
    // eslint-disable-next-line
    object = object[keysArr[index++]];
  }
  return object;
};

// 遍历
export const {map} = fp; // 对数组中的每个元素执行一个函数，并返回执行结果组成的新数组
export const {filter} = fp; // 对数组进行过滤，返回符合条件的元素组成的新数组
export const {compact} = fp; // 过滤掉数组中的空值、false、null 和 undefined
export const {forEach} = fp; // 对数组或对象中的每个元素执行一个函数
export const {forEachRight} = fp; // 从数组或对象的最后一个元素开始，对每个元素执行一个函数
export const {mapValues} = fp; // 对对象的每个属性值执行一个函数，并返回执行结果组成的新对象
export const {flatMap} = fp; // 对数组中的每个元素执行一个函数，并将执行结果组成的数组展开成一个新的数组
export const {pullAllBy} = fp; // 从数组中删除符合条件的元素，返回一个新数组
export const {pullAll} = fp; // 从数组中删除指定的元素，返回一个新数组


// 函数执行
export const {flow} = fp; // 将多个函数组合成一个函数，按照从左到右的顺序依次执行
export const {debounce} = fp; // 用于防抖处理，即在一定时间内只执行一次函数
export const {identity} = fp; // 返回传入的参数

// 判断
export const {isEmpty} = fp; // 判断一个值是否为空
export const {isEqual} = fp; // 判断两个值是否相等
export const {eq} = fp; // 深对比，判断两个值是否相等
export const {isArray} = fp; // 判断一个值是否为数组
export const noEmpty = (data: any) => !fp.isEmpty(data); // 判断一个值是否不为空
export const {has} = fp; // 判断一个对象是否具有指定的属性
export const {lt} = fp; // 判断一个数是否小于另一个数
export const {lte} = fp; // 判断一个数是否小于等于另一个数
export const {gt} = fp;// 判断一个数是否大于另一个数
export const {gte} = fp; // 判断一个数是否大于等于另一个数
export const {isUndefined} = fp; // 判断一个值是否为 undefined
export const {isElement} = fp; // 判断一个值是否为 DOM 元素
export const {isString} = fp; // 判断一个值是否为字符串
export const {isNumber} = fp; // 判断一个值是否为数字
export const {startsWith} = fp;// 判断一个字符串是否以指定的字符开头
export const {isNil} = fp; // 判断一个值是否为 null 或 undefined
export const {isNull} = fp; // 判断一个值是否为 null
export const {isFunction} = fp; // 判断一个值是否为函数
export const {isObject} = fp; // 判断一个值是否为对象
export const {some} = fp; // 判断数组中是否存在符合条件的元素
export const isTrue = (val: string | undefined) => isEqual(val,IBoolean.Y); // 判断传入的值是否等于 IBoolean.Y，即判断后端是否返回true
export const isFalse = (val: string | undefined) => isEqual(val,IBoolean.N); // i判断传入的值是否等于 IBoolean.N，判断后端是否返回false
export const {isInteger} = fp; // 判断是否是一个整数

// 查找
export const {find} = fp; // 查找数组中符合条件的元素，返回第一个符合条件的元素
export const {includes} = fp; // 判断一个数组是否包含指定的元素
export const {findIndex} = fp; // 查找数组中符合条件的元素的下标
export const {differenceBy} = fp; // 对两个数组进行比较，返回第一个数组中存在而第二个数组中不存在的元素组成的新数组
export const findIndexed = (fp.find as IObject).convert({ cap: false }); // 对数组进行查找，返回符合条件的元素。函数可以接收元素的索引作为参数

// 操作
export const {concat} = fp; // 将多个数组合并成一个数组
export const {sortBy} = fp; // 对数组进行排序
export const {split} = fp; // 将字符串按照指定的分隔符分割成数组
export const {uniqBy} = fp; // 根据指定的条件对数组进行去重
export const {uniq} = fp; // 对数组进行去重
export const {drop} = fp; // 从数组中删除指定数量的元素，并返回一个新数组
export const {flatten} = fp; // 将多维数组转换为一维数组
export const {cloneDeep} = fp; // 深度克隆一个对象或数组
export const {result} = fp; // 获取对象属性的值，如果属性值是一个函数，则执行该函数并返回执行结果
export const {omit} = fp; // 从对象中删除指定的属性，返回一个新对象
export const {omitBy} = fp;// 从对象中删除符合条件的属性，返回一个新对象
export const {assign} = fp; // 将多个对象合并成一个对象
export const {mergeWith} = fp; // 将多个对象合并成一个对象，如果有相同的属性，则执行指定的合并函数处理属性值
export const {merge} = fp; // 将多个对象合并成一个对象，如果有相同的属性，则后面的对象会覆盖前面的对象的属性值
export const {slice} = fp; // 
export const {take} = fp;  //
export const {takeRight} = fp;

// 计算
export const {size} = fp; // 获取数组或对象的长度或大小
export const {sum} = fp; // 对数组中的数值元素进行求和
export const {reduce} = fp; // 对数组进行归纳计算，返回一个值

// 分组
export const {chunk} = fp; // 将一个数组按指定大小分割成若干个子数组
export const {groupBy} = fp; // 将数组按指定的条件分组

// 字符串处理
export const {trim} = fp; // 去除字符串两端的空格
export const {toUpper} = fp; // 将字符串转换为大写
export const {join} = fp; // 将数组按指定的分隔符拼接成字符串
export const {truncate} = fp; // 截断字符串，如果字符串超过指定长度，则用指定的字符串替换超出部分
export const {replace} = fp; // ：替换字符串中符合条件的部分
export const {padStart} = fp; // 在字符串的开头添加指定的字符串，直到字符串的长度达到指定的长度


export const {range} = fp; // 生成一个指定范围的数组
// 对 lodash 函数进行了扩展，使它们支持接收索引参数。

export const forEachIndexed = (fp.forEach as IObject).convert({
  cap: false,
}); // 对数组或对象的每个元素执行一个函数，并且函数可以接收元素的索引作为参数
export const mapIndexed = (fp.map as IObject).convert({ cap: false }); // 对数组或对象的每个元素执行一个函数，并返回执行结果组成的新数组。函数可以接收元素的索引作为参数
export const mapValuesIndexed = (fp.mapValues as IObject).convert({ cap: false }); // 对对象的每个属性值执行一个函数，并返回执行结果组成的新对象。函数可以接收属性名作为参数
export const rejectIndexed = (fp.reject as IObject).convert({
  cap: false,
}); // 对数组或对象进行过滤，返回不符合条件的元素组成的新数组。函数可以接收元素的索引作为参数
export const reduceIndexed = (fp.reduce as IObject).convert({
  cap: false,
}); // 对数组或对象进行归纳计算，返回一个值。函数可以接收元素的索引作为参数
export const filterIndexed = (fp.filter as IObject).convert({
  cap: false,
}); // 对数组或对象进行过滤，返回符合条件的元素组成的新数组或新对象。函数可以接收元素的索引作为参数



/**
 * @description: 将数组转为map结构，例如 normalize([{code:"a"},{code:'b'}],'code')=>{a:{code:"a"},b:{code:"b"}}
 * @param {any} arr
 * @param {*} key
 * @return {*}
 */
export const normalize = (arr: any[], key = 'id', transformer: any = identity) => {
  // eslint-disable-next-line
  const getKeyValue: any = isFunction(key) ? key : (includes('.')(key) ? getProp(key) : fastProp(key));
  const obj: any = {};
  if (isEmpty(arr)) {
    return {};
  }
  if (!isArray(arr)) {
    return {
      [getKeyValue(arr)]: arr,
    };
  }
  const length = size(arr);
  for (let i = 0; i < length; i += 1) {
    obj[getKeyValue(arr[i])] = transformer(arr[i]);
  }
  return obj;
};

/**
 * @description: 将数组转为Map结构，例如 normalize([{code:"a"},{code:'b'}],'code')=> Map(1){'1612737226530758658' => {…}}
 * @param {any} arr
 * @param {*} key
 * @return {*}
 */
export const normalizeMap = (arr: any[], key = 'id', transformer: any = identity): Map<string, any> => {
  // eslint-disable-next-line
  const getKeyValue: any = isFunction(key) ? key : (includes('.')(key) ? getProp(key) : fastProp(key));
  const mapS = new Map<string, any>();
  if (isEmpty(arr)) return mapS;

  if (!isArray(arr)) {
    mapS.set(getKeyValue(arr), arr);
    return mapS;
  }

  const length = size(arr);

  for (let i = 0; i < length; i += 1) {
    mapS.set(getKeyValue(arr[i]), transformer(arr[i]));
  }

  return mapS;
};

/**
 * @description: 将数组转为map结构，例如 strArrayToMap(['a','b','c']) => { a: true, b: true, c: true }
 * @param {any} arr
 * @return {*}
 */
export const strArrayToMap = (strs: string[]) => {
  const strMap: any = {};
  forEach((str: string) => {
    strMap[str] = true;
  })(strs);
  return strMap;
};

// 日志打印
export const log = (data: any) => {
  // eslint-disable-next-line
  console.log(data)
  return data;
};


// 替换换行符
export const replaceN = (str: string) => replace(/\n\n/g, "\n")(str)


// 当前时间
export const getNow = () => new Date().toISOString()


export const updateItem = (target: any)=>(meta: any)=>(data:any[], key='id')=>map((item: any) => {
  if (item[key]=== target) {
    return {
      ...item,
      ...meta,
    };
  }
  return item;
})(data)


export const replaceItem = (target: any)=>(meta: any)=>(data:any[], key='id')=>map((item: any) => {
  if (item[key]=== target) {
    return {
      ...item,
      ...meta,
    };
  }
  return item;
})(data)