import { memo, useCallback, useState } from "react";
import { Button } from "antd";
import { useGo } from "../../../hooks/useGo";
import { getGptLink } from "../../../utils/link";
import { useGptStore } from "../../../store/gpt";
import Remove from "./Remove";

const OperateBar = ({ gpt, setActive }: any) => {
  const { go } = useGo();
  const { newGpt } = useGptStore();
  const [loading, setLoading] = useState(false);

  const onPreview = useCallback(() => {
    if (gpt.id) {
      go(`/gpt/${gpt.id}`);
    }
  }, [go, gpt]);
  const onUpdate = useCallback(() => {
    setLoading(true);

    if (gpt.id) {
      newGpt({ url: getGptLink(gpt) }).finally(() => {
        setLoading(false);
      });
    }
  }, [go, gpt]);

  return (
    <div className="p-4 bg-gray-100">
      <Button className="mr-4" onClick={onPreview}>
        Perview
      </Button>
      <Button className="mr-4" loading={loading} onClick={onUpdate}>
        Update
      </Button>
      <Remove gpt={gpt} setActive={setActive} />
    </div>
  );
};

export default memo(OperateBar);
