// 写一个组件，设置用户的头像和昵称
import type { FC} from "react";
import React, { memo, useCallback, useState } from "react";
import { Button, Input, notification } from "antd";
import UploadAvatar from "./UploadAvatar";
import { useGo } from "../../hooks/useGo";
import { useUserStore } from "../../store/user";
import { isEmpty, trim, prop } from "../../utils/fp";
import AxiosService from "../../api/AxiosService";

export const UserAvatar: FC = () => {
  const [avatar, setAvatar] = useState<string>("");
  const [nickname, setNickname] = useState<string>("");
  const [notificationCall, contextHolder] = notification.useNotification();
  const { setUser } = useUserStore();
  const {  navigate } = useGo();

  const onChange = useCallback((e: any) => {
    setNickname(trim(e.target.value));
  }, []);

  const submitProfile = useCallback(() => {
    if (isEmpty(avatar)) {
      notificationCall.error({
        message: "请上传头像",
      });
      return;
    }
    if (isEmpty(nickname)) {
      notificationCall.error({
        message: "请输入昵称",
      });
      return;
    }
    AxiosService.post("/api/update-profile", {
      avatar,
      nickname,
    })
      .then((res: any) => {
        setUser(res);
        navigate('/discover')

      })
      .catch((err) => {
        notificationCall.error({
          message: prop("error")(err),
        });
      });
  }, [avatar, nickname, notificationCall, setUser,navigate]);

  return (
    <div>
      {contextHolder}
      <div className="flex  flex-col items-center  bg-white p-8  mb-4 rounded-[10px]">
        <div className="flex justify-center">
          <UploadAvatar onUpload={setAvatar} />
        </div>
        <div className="mt-4">
          <div className="text-[16px] mb-2 ">
            Nickname <span className="text-red">*</span>
          </div>

          <Input
            value={nickname}
            onChange={onChange}
            className=""
            size="large"
            placeholder="请输入昵称"
          />
        </div>
      </div>
      <Button
        type="primary"
        size="large"
        className="w-full mb-4"
        onClick={submitProfile}
      >
        下一步
      </Button>
    </div>
  );
};

export default memo(UserAvatar);
