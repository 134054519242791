// 向右滑动按钮
import { useContext } from "react";
import { RightOutlined } from "@ant-design/icons";
import { VisibilityContext } from "react-horizontal-scrolling-menu";

function Right() {
  const { isLastItemVisible, scrollNext } = useContext(VisibilityContext);

  return (
    <div
      className={`${
        isLastItemVisible ? "hidden" : "block"
      }  flex items-center justify-center rounded-[26px] bg-white absolute right-[-20px]  top-[30px] z-[10] w-[52px] h-[52px]   cover-shadow`}
      onClick={() => scrollNext()}
    >
      <RightOutlined className="text-gray-600" />
    </div>
  );
}

export default Right;
