import { memo } from "react";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import { Link } from "react-router-dom";
import Left from "./Left";
import Right from "./Right";
import { map, prop } from "../../../../utils/fp";
import Card from "./Card";
import "react-horizontal-scrolling-menu/dist/styles.css";
import { useHotStore } from "../../../../store/hot";

function Store({ store }: { store?: any }) {
  const { gptMap } = useHotStore();
  const gpts = map((id: string) => prop(id)(gptMap))(store.gpts);
  return (
    <div
      key={store?.id}
      className="w-full max-w-full bg-white  py-[20px] relative"
    >
      <Link to={`/store/${store?.id}`}>
        <div className="mb-4 px-[20px] title text-[22px] ">
          {store?.name}
          <div className="desc ">
            {store?.description}
          </div>
        </div>
      </Link>
      <div className="w-[320px)] md:w-[1288px] relative">
        <ScrollMenu LeftArrow={Left} RightArrow={Right}>
          {map((gpt: any) => <Card key={gpt?.id} gpt={gpt} />)(gpts)}
        </ScrollMenu>
      </div>
    </div>
  );
}

export default memo(Store);
