export const categories = {
  "Virtual Assistants": ["assistant", "virtual", "help", "support"],
  "Image Generation": ["image", "photo", "generate", "create"],
  "Coding Help": ["code", "programming", "develop", "script"],
  "Creative Writing": ["creative", "write", "story", "fiction"],
  Robotics: ["robot", "automation", "mechanical", "machine"],
  ChatBots: ["chatbot", "conversational", "dialogue", "messaging"],
  Toggle: ["toggle", "switch", "change", "adjust"],
  "Tech Support": ["tech", "support", "helpdesk", "technology"],
  "Virtual Reality": ["virtual reality", "vr", "3d", "immersive"],
  "Fitness Coaching": ["fitness", "health", "exercise", "workout"],
  SEO: ["seo", "search engine", "optimization", "ranking"],
  Fun: ["fun", "entertainment", "play", "game"],
  "Personalized Learning": ["personalized", "learning", "education", "custom"],
  Research: ["research", "study", "investigate", "explore"],
  Translation: ["translate", "language", "interpret", "convert"],
  Marketing: ["marketing", "promotion", "advertising", "brand"],
  "E-learning": ["e-learning", "online learning", "education", "course"],
  "Augmented Reality": ["augmented reality", "ar", "enhanced", "interactive"],
  "Medical Advice": ["medical", "health", "advice", "doctor"],
  "Legal Drafting": ["legal", "law", "contract", "draft"],
  "Video Creation": ["video", "create", "film", "produce"],
  "Data Analysis": ["data", "analysis", "analytics", "statistics"],
  Scriptwriting: ["script", "write", "screenplay", "drama"],
  "Speech Recognition": ["speech", "recognition", "voice", "listen"],
  "Strategy Planning": ["strategy", "plan", "business", "tactic"],
  "Sentiment Analysis": ["sentiment", "emotion", "opinion", "feedback"],
  Poetry: ["poetry", "poem", "verse", "rhyme"],
  "Job Analysis": ["job", "career", "occupation", "profession"],
  "Social Media": ["social media", "network", "online", "community"],
  "News Writing": ["news", "journalism", "report", "media"],
  Education: ["education", "learn", "teach", "school"],
  "E-commerce": ["e-commerce", "online shopping", "retail", "store"],
  Productivity: ["productivity", "efficiency", "work", "manage"],
  Gaming: ["game", "play", "video game", "interactive"],
  "Mental Health": ["mental health", "psychology", "wellness", "therapy"],
  Nutrition: ["nutrition", "diet", "food", "health"],
  "Autonomous Driving": ["autonomous", "driving", "vehicle", "self-driving"],
  Science: ["science", "research", "study", "experiment"],
  Design: ["design", "art", "visual", "creative"],
  Finance: ["finance", "money", "economy", "banking"],
  Summarization: ["summarize", "brief", "concise", "overview"],
  "Competitor Analysis": ["competitor", "rival", "market", "analysis"],
  Music: ["music", "song", "melody", "audio"],
  "Customer Service": ["customer service", "support", "help", "care"],
  "Real Estate": ["real estate", "property", "housing", "land"],
  "Environmental Assessment": [
    "environment",
    "ecology",
    "assessment",
    "sustainability",
  ],
  "Data Modeling": ["data modeling", "database", "structure", "analysis"],
  "HR Management": ["hr", "human resources", "employee", "staff"],
  "Supply Chain": ["supply chain", "logistics", "distribution", "inventory"],
  "Audio Processing": ["audio", "sound", "process", "edit"],
  "Text to Speech": ["text-to-speech", "speech", "voice", "convert"],
  "Art and Design": ["art", "design", "visual", "creative"],
};

export const categoriesList = [
  { category: "Virtual Assistants", count: 303 },
  { category: "Image Generation", count: 220 },
  { category: "Augmented Reality", count: 131 },
  { category: "Other", count: 126 },
  { category: "Coding Help", count: 66 },
  { category: "Creative Writing", count: 53 },
  { category: "Fun", count: 38 },
  { category: "Tech Support", count: 24 },
  { category: "Personalized Learning", count: 24 },
  { category: "SEO", count: 16 },
  { category: "Research", count: 14 },
  { category: "Translation", count: 13 },
  { category: "Robotics", count: 11 },
  { category: "Toggle", count: 9 },
  { category: "Medical Advice", count: 9 },
  { category: "Data Analysis", count: 8 },
  { category: "Fitness Coaching", count: 7 },
  { category: "ChatBots", count: 5 },
  { category: "Virtual Reality", count: 5 },
  { category: "News Writing", count: 4 },
  { category: "Video Creation", count: 4 },
  { category: "Marketing", count: 4 },
  { category: "Strategy Planning", count: 4 },
  { category: "Sentiment Analysis", count: 3 },
  { category: "Legal Drafting", count: 3 },
  { category: "Social Media", count: 3 },
  { category: "Finance", count: 2 },
  { category: "Speech Recognition", count: 2 },
  { category: "Real Estate", count: 2 },
  { category: "Summarization", count: 1 },
  { category: "HR Management", count: 1 },
  { category: "Design", count: 1 },
  { category: "Education", count: 1 },
  { category: "Job Analysis", count: 1 },
  { category: "Data Modeling", count: 1 },
];
