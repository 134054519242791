import { memo, useCallback, useState, useMemo } from "react";
import { AppstoreOutlined } from "@ant-design/icons";
import { Avatar } from "antd";
import { noEmpty, prop, first } from "../../../utils/fp";
import EditDetails from "./EditDetails";
import { getCover } from "../../../utils/cdn";


const Cover = ({ store, setActive, gpts }: any) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const onEdit = useCallback(() => {
    setIsModalOpen(true);
  }, []);
  const cover = useMemo(() => {
    const storeCover = getCover(store);
    if (storeCover) {
      return storeCover;
    }
    if (noEmpty(gpts)) {
      const gpt = first(gpts);
      return gpt?.profile_picture_url;

    }
    return "";
  },  [store]);
  return (
    <div>
      <div className="flex justify-between   relative  ">
        <div className="mt-[48px]">
          <h1
            onClick={onEdit}
            className="text-[4rem] leading-[4.75rem] cursor-pointer   "
          >
            {store?.name}
          </h1>
          <div className="mt-4">{prop("description")(store)}</div>
          
        </div>
        <Avatar
          onClick={onEdit}
          className="absolute right-0 top-[48px] w-[240px] flex items-center justify-center  h-[240px] cover-shadow   "
          src={cover}
          icon={<AppstoreOutlined className="text-[120px] " />}
        />
      </div>
      <EditDetails
        store={store}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        setActive={setActive}
      />
    </div>
  );
};

export default memo(Cover);
