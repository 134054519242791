import { last } from '../../utils/fp';

// This utility function takes in a string and a function that tells where to capitalize, then return the capitalized string. 
// 这个实用函数接收一个字符串和一个函数，函数指明在哪个位置大写，然后返回大写后的字符串。
function capitalizeAt(string:string, indexFunc: any) {
  return string
    .split(" ")
    .map((word) => {
      const index = indexFunc(word);
      return word.slice(0, index) + word.charAt(index).toUpperCase() + word.slice(index + 1);
    })
    .join(" ");
}
// This function extracts the last segment from the input string and capitalize the first letter of the segment.
// 此函数从输入字符串中提取最后一个部分，并使该部分的首字母大写。
export function extractAndCapitalizeLastSegment(inputString: string | undefined) {
  // early return when input is empty or undefined
  // 当输入为空或未定义时提前返回
  if (!inputString) {
    return "";
  }
  
  // Split string into segments and get the last one as identifier
  // 将字符串分割成多个部分，并把最后一个部分作为标识符
  const segments = inputString.split("-");
  return capitalizeAt(last(segments), () => 0);
}