import { memo, useMemo } from "react";
import GptsList from "../../../components/Gpts/Cards";

import { map, noEmpty, prop, flow, compact } from "../../../utils/fp";
import { useGptStore } from "../../../store/gpt";

const Gpts = ({ store }: any) => {
  const { gptMap } = useGptStore();

  const gpts = useMemo(() => {
    if (store?.gptsData) {
      return store?.gptsData;
    }
    const list = flow(
      map((id: string) => prop(id)(gptMap)),
      compact
    )(store?.gpts);
    return list;
  }, [store]);

  return (
    <div>
      <div className="p-[20px] flex  justify-center ">
        {noEmpty(gpts) && <GptsList gpts={gpts} />}
      </div>
    </div>
  );
};

export default memo(Gpts);
