import { Link } from "react-router-dom";
import { Result, Button } from "antd";
import { useTranslation } from "react-i18next"
import styles from './styles.scss'

function NoMatch() {
  const { t } = useTranslation();
  return (
    <Result
      status="404"
      title="404"
      className={styles.page}
      style={{ height:"100%", width:"100%", position: "absolute" }}
      subTitle={t('pageNotFound')}
      extra={<Link to="/discover"><Button type="primary">{t('goBackHome')}</Button></Link>}
    />
  );
}

export default NoMatch;
