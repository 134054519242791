import assert from './utils/assert';
import objectToGetParams from './utils/objectToGetParams';
import createShareButton from './hocs/createShareButton';

function livejournalLink(url, { title, description }) {
  assert(url, 'livejournal.url');

  return (
    `https://www.livejournal.com/update.bml${ 
    objectToGetParams({
      subject: title,
      event: description,
    })}`
  );
}

const LivejournalShareButton = createShareButton(
  'Livejournal',
  livejournalLink,
  props => ({
    title: props.title,
    description: props.description,
  }),
  {
    windowWidth: 660,
    windowHeight: 460,
  },
);

export default LivejournalShareButton;
