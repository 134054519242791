import { prop, truncate } from "./fp";

const WEBSITE = "https://gpt-store.io";

export const getGptLink = (gpt: any) => {
  const url = prop("gizmo.short_url")(gpt);
  if (url) {
    return `https://chat.openai.com/g/${url}`;
  }
  // eslint-disable-next-line
  return "javascript:void";
};

export const getShareLink = (path: string) => `${WEBSITE}${path}`;


export const getGptShareLink = (gpt: any) => {
  const id = prop("id")(gpt);
  let url = WEBSITE;
  if (id) {
    url = `${url}/gpt/${id}`;
  }
  // eslint-disable-next-line
  return url;
};

export const getShareDesc = (desc: string) => truncate({
    str: desc,
    len: 120,
    omission: "...",
  })(desc);

export const stopPropagation =(e: any)=>{
    e.stopPropagation();
   // e.preventDefault();

};
