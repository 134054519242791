import { useCallback } from "react";
import { useTranslation } from "react-i18next";

const setMetaTag = (attrName: string, attrValue: string, content: string) => {
  let element = document.querySelector(`meta[${attrName}="${attrValue}"]`);
  if (!element) {
    element = document.createElement("meta");
    element.setAttribute(attrName, attrValue);
    document.head.appendChild(element);
  }
  element.setAttribute("content", content);
};

interface SEOProps {
  title?: string;
  keywords?: string;
  description?: string;
  ogUrl?: string;
  ogImage?: string;
}

export const useSEO = () => {
  const { t } = useTranslation();
  const setSEO = useCallback(
    ({
      title,
      keywords,
      description,
      
      ogUrl,
      
      ogImage,
    }: SEOProps) => {
      // 设置标题
      if (title) document.title = title || t("seoTitle");

      // 设置关键字
      setMetaTag("name", "keywords", keywords || t("seoKeywords"));

      // 设置描述
      setMetaTag("name", "description", description || t("seoDescription"));

      // 设置 Open Graph 标签
      setMetaTag("property", "og:title",   title || t("seoTitle"));
      setMetaTag(
        "property",
        "og:url",
        ogUrl ? `https://gpt-store.io${ogUrl}` : "https://gpt-store.io/"
      );
      setMetaTag(
        "property",
        "og:description",
          description || t("seoDescription")
      );
      setMetaTag(
        "property",
        "og:image",
        ogImage || "https://cdn.gpt-store.io/logo512.png"
      );
    },
    []
  );

  return setSEO;
};
