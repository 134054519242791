import { memo } from "react";
import { Avatar, Progress } from "antd";
import { Link } from "react-router-dom";
import Try from "../Try/index";

import { mapIndexed, first, prop, flow } from "../../utils/fp";
import {
  formatNumberWithCommas,
  formatNumberToFixed,
} from "../../utils/format";

const Gpts = ({ gpts }: any) => {
  const topVisits = flow(first, prop("visits"))(gpts);
  return (
    <ul className="flex-1 desc  ">
      {mapIndexed((item: any, index: number) => (
        <Link to={`/gpt/${item.id}`}>
          <li className="hover:bg-gray-100 flex  border-b border-gray-100 px-4  items-center justify-between py-4 cursor-pointer">
            <div className="flex items-center ">
              <div className="flex w-[32px] mr-2 text-[16px] items-center justify-center  ">
                {Number(index) + 1}
              </div>
              <Avatar
                src={item?.profile_picture_url}
                className="mr-2 w-[48px] h-[48px] cover-shadow"
              />
              <div className="flex flex-1 flex-col justify-center ">
                <div className="title text-[16px] ">{item?.name}</div>
                <div className="hidden label md:block">{item?.description}</div>
                <div className="md:hidden flex  mr-6">
                  <div className="title text-[14px] mr-1">
                    {formatNumberWithCommas(item?.visits)}
                  </div>

                  <Progress
                    percent={formatNumberToFixed(
                      ((item?.visits || 0) / topVisits) * 100,
                      1
                    )}
                    className="w-[100px]"
                    size="small"
                  />
                </div>
              </div>
            </div>
            <div className="flex">
              <div className="hidden md:block md:w-[120px] mr-6">
                <div className="title text-[12px] md:text-[16px]">
                  {formatNumberWithCommas(item?.visits)}
                </div>

                <Progress
                  percent={formatNumberToFixed(
                    ((item?.visits || 0) / topVisits) * 100,
                    1
                  )}
                  size="small"
                />
              </div>

              <Try gpt={item} />
            </div>
          </li>
        </Link>
      ))(gpts)}
    </ul>
  );
};

export default memo(Gpts);
