import { useEffect } from "react";
import { noEmpty } from "../../utils/fp";
import TopGpts from "../../components/Gpts/TopGpts";
import { useSEO } from "../../hooks/useSeo";
import Nav from "./components/Nav";
import { useGptMap } from "./hooks/useGptMap";
import Loading from "../../components/Loading";
import { getShareLink } from "../../utils/link";

function Top() {
  const setSeo = useSEO();

  useEffect(() => {
    setSeo({
      title: "Hot Custom GPTs",
      description: "Hot Custom GPTs",
      keywords:
        "Top Custom GPTs,Hot Custom GPTs,GPT Rankings, Best GPT, GPT-Store,   New GPTs, GPTS",
      ogUrl: `/top`,
    });
  }, []);

  const current = "all";
  const { gpts } = useGptMap();

  return (
    <div className="w-full max-w-[1288px] m-auto ">
      <Nav current={current} shareUrl={getShareLink("/top")} />
      {noEmpty(gpts) ? (
        <div>
          <TopGpts gpts={gpts} />
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
}

export default Top;
