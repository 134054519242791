import { useEffect } from "react";
import { useSEO } from "../../hooks/useSeo";
import { useHotStore } from "../../store/hot";
import Store from "./components/Store/index";
import { map, noEmpty } from "../../utils/fp";
import Loading from "../../components/Loading";

function Discover() {
  const { stores } = useHotStore();
  const setSeo = useSEO();
  useEffect(() => {
    setSeo({
      title: "Discover the Most Fascinating GPT",
      ogUrl: `/discover`,
    });
  }, []);

  return (
    <div className="w-full max-w-[1288px] m-auto ">
      {noEmpty(stores) ? (
        map((store: any) => <Store store={store} />)(stores)
      ) : (
        <Loading />
      )}
    </div>
  );
}

export default Discover;
