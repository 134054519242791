import React from "react";

import { Outlet } from "react-router-dom";

import Bar from "./Bar";
import Footer from "./Footer";

const App: React.FC<any> = () => (
    <div className="">
      <Bar />
      <div className="pt-[64px]">
        <Outlet />
      </div>
      <Footer />
    </div>
  );

export default App;
