import { useEffect, useState, useCallback } from "react";
import { Modal, Divider, Input, Button } from "antd";
import { Link } from "react-router-dom";
import { useUserStore } from "../../store/user";
import { useSetUserInfo } from "../../hooks/useSetUserInfo";
import { trim } from "../../utils/fp";
import AxiosService from "../../api/AxiosService";

const id = "google-login";

const LoginButton = () => {
  const { loginModal, setLoginModal } = useUserStore();
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const { setUserInfo } = useSetUserInfo();

  useEffect(() => {
    if (loginModal) {
      try {
        // @ts-ignore
        window.google?.accounts?.id.renderButton(
          document.getElementById(id), // 按钮渲染的容器元素
          { theme: "outline", size: "large" } // 可选的按钮样式配置
        );
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
      }
    }
  }, [loginModal]);

  const onChangeEmail = useCallback((e: any) => {
    setEmail(trim(e.target.value));
  }, []);

  const onChangePassword = useCallback((e: any) => {
    setPassword(trim(e.target.value));
  }, []);

  const hideModal = () => {
    setLoginModal(false);
  };

  const onLogin = () => {
    setLoading(true);
    AxiosService.post("/api/login", {
      email,
      password,
    }).then((res:any) => {
      // eslint-disable-next-line no-console
      console.log(res);
      setUserInfo(res?.user, res?.token);
      setLoading(false);
      setLoginModal(false);
    });
   
  };

  return (
    <Modal
      title="Log in "
      open={loginModal}
      forceRender
      maskClosable={false}
      mask={false}
      footer={null}
      width={400}
      onCancel={hideModal}
      zIndex={2000}
    >
      <div className="my-8">
        <div className="">
          <Input
            className="mb-6 text-[16px] w-full h-[48px] "
            value={email}
            onChange={onChangeEmail}
            placeholder="Email Address"
          />
          <Input.Password
            className="mb-6 text-[16px] w-full h-[48px] "
            value={password}
            onChange={onChangePassword}
            placeholder="Password"
          />

          <Button
            type="primary"
            loading={loading}
            className="mb-4 text-[16px] w-full h-[48px] "
            onClick={onLogin}
          >
            Continue
          </Button>
        </div>
        <div>
          Don't have an account? <Link to="/sign-up">Sign up</Link>
        </div>
        <Divider>OR</Divider>
        <div id={id} />
      </div>
    </Modal>
  );
};

export default LoginButton;
