import React, { useCallback, useState } from "react";
import { Modal } from "antd";
import ShareIcon from "./react-share/icons/Share";

import ShareOn from "./components/ShareOn";

function Share({ title, shareUrl, shareImage }: any) {
  const [isShareVisible, setIsShareVisible] = useState(false);
  const onShow = useCallback(() => {
    setIsShareVisible(true);
  }, []);

  const [hashtags] = useState(["GPTs", "GPTStore"]);

  const handleOk = () => {
    setIsShareVisible(false);
  };

  return (
    <div>
      <div className=" cursor-pointer flex" onClick={onShow}>
        {" "}
        <ShareIcon /> <div className="ml-[8px] text-[14px] link">
          Share
        </div>{" "}
      </div>
      <Modal
        title="Share"
        open={isShareVisible}
        onOk={handleOk}
        onCancel={handleOk}
        footer={null}
        width={800}
      >
        <ShareOn
          title={title}
          hashtags={hashtags}
          shareUrl={shareUrl}
          shareImage={shareImage}
        />
      </Modal>
    </div>
  );
}

export default Share;
