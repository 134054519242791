import { memo, useState, useCallback } from "react";
import { notification, Button } from "antd";
import AxiosService from "../../../api/AxiosService";

import { useGptStore } from "../../../store/gpt";

interface Props {
  gpt: any;
  setActive: (active: any) => void;
}

const Role = ({ gpt, setActive }: Props) => {
  const { removeGpt } = useGptStore();

  const [loading, setLoading] = useState(false);

  const [notificationCall, contextHolder] = notification.useNotification();

  const onRemove = useCallback(
    (e: any) => {
      e.stopPropagation();
      e.preventDefault();
      if (loading) {
        return;
      }
      setLoading(true);
      AxiosService.post("/api/remove-gpt", {
        id: gpt.id,
      })
        .then(() => {
          notificationCall.success({
            message: "已删除",
          });
          removeGpt(gpt.id);
          setActive({});
        })
        .catch(() => {
          notificationCall.error({
            message: "删除失败",
          });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [gpt, removeGpt, loading, setActive]
  );

  return (
    <>
      {contextHolder}
      <Button loading={loading} className="" onClick={onRemove}>
        Remove
      </Button>
    </>
  );
};

export default memo(Role);
