import Markdown from "../../components/Markdown/index";

function Terms() {
  return (
    <div className="flex justify-center p-4">
      <div className="w-[900px]">
        <Markdown>{`# Privacy Policy

## 1. Introduction
We at GPT-Store highly value your privacy. This Privacy Policy is designed to clearly communicate how we collect, use, protect, and in certain circumstances, disclose your information.

## 2. Information Collected

Personal Identification Information: We may collect your name, email address, or other details when you register on our site, subscribe to our newsletter, fill out a form, or use other site features.
Non-Personal Identification Information: We may collect non-personal information about your site usage, such as browser type, time of visit, etc.
## 3. Use of Information
We may use your information to:

- Personalize your experience
- Improve our website
- Provide customer service
- Send periodic email updates

## 4. Protection of Information
We adopt appropriate data collection, storage, and processing practices to protect your personal information against unauthorized access, alteration, disclosure, or destruction.

## 5. Third-Party Disclosure
We do not sell, trade, or otherwise transfer your personally identifiable information to external parties. This does not include trusted third parties who assist us in operating our website, conducting our business, or servicing you, as long as those parties agree to keep this information confidential.

## 6. Non-Personal Identification Information
We may provide or share non-personal identification information with others for marketing, advertising, or other uses.

## 7. Third-Party Links
Occasionally, at our discretion, we may include or offer third-party products or services on our website. These third-party sites have separate and independent privacy policies.

## 8. Consent
By using our site, you consent to our privacy policy.

## 9. Policy Changes
We reserve the right to update this privacy policy at any time.

## 10. Contact Us
If you have any questions about this privacy policy, please contact us.

`}</Markdown>
      </div>
    </div>
  );
}

export default Terms;
