import assert from './utils/assert';
import objectToGetParams from './utils/objectToGetParams';
import createShareButton from './hocs/createShareButton';

function tumblrLink(url, { title, caption, tags, posttype }) {
  assert(url, 'tumblr.url');

  return (
    `https://www.tumblr.com/widgets/share/tool${ 
    objectToGetParams({
      canonicalUrl: url,
      title,
      caption,
      tags,
      posttype,
    })}`
  );
}

const TumblrShareButton = createShareButton(
  'tumblr',
  tumblrLink,
  props => ({
    title: props.title,
    tags: (props.tags || []).join(','),
    caption: props.caption,
    posttype: props.posttype || 'link',
  }),
  {
    windowWidth: 660,
    windowHeight: 460,
  },
);

export default TumblrShareButton;
