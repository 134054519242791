// 更新用户信息
import { useCallback } from "react";
import Cookies from "js-cookie";
import { useUserStore } from "../store/user";
import { USER_INFO, TOKEN_KEY } from "../constants/user";
import LocalStorageService from "../service/LocalStorageService";

export const useSetUserInfo = () => {
  const { setUser } = useUserStore();

  const setUserInfo = useCallback(
    (user: any, token?: string) => {
      if (token) {
        Cookies.set(TOKEN_KEY, token, { expires: 20 });
      }

      LocalStorageService.setItem(USER_INFO, user);
      setUser(user);
    },
    [setUser]
  );

  return {
    setUserInfo,
  };
};
