/* eslint-disable */

import cc from "classcat";
import "github-markdown-css";
import "katex/dist/katex.min.css";
import type { FC, ReactNode } from "react";
import { useEffect, useMemo, useState } from "react";
import { Tooltip } from "antd";
import { Copy } from "@icon-park/react";
import { CopyToClipboard } from "react-copy-to-clipboard";

import ReactMarkdown from "react-markdown";
import reactNodeToString from "react-node-to-string";
import rehypeHighlight from "rehype-highlight";
import remarkBreaks from "remark-breaks";
import remarkGfm from "remark-gfm";
import remarkMath from "remark-math";
import supersub from "remark-supersub";
import "./markdown.css";
import codeStyles from "./code.module.scss";
import "highlight.js/styles/vs2015.css";
import { extractAndCapitalizeLastSegment } from "./utils";
import { size } from "../../utils/fp";

function CustomCode(props: {
  children: ReactNode;
  className?: string;
  language?: string;
}) {
  const [copied, setCopied] = useState(false);

  const code = useMemo(
    () => reactNodeToString(props.children),
    [props.children]
  );

  useEffect(() => {
    if (copied) {
      setTimeout(() => setCopied(false), 3000);
    }
  }, [copied]);

  return (
    <div className={codeStyles.card}>
      <div className={codeStyles.header}>
        <div className={codeStyles.title}>
          <span className="font-bold">
            {extractAndCapitalizeLastSegment(props.className)}
          </span>
          <CopyToClipboard
            className="cursor-pointer font-bold flex items-center"
            text={code}
            onCopy={() => setCopied(true)}
          >
            <div className="flex items-center">
              <Copy className="inline-block mr-1" theme="outline" size="16" fill="#fff" />
              <span>{copied ? "Copied!" : "Copy Code"}</span>
            </div>
          </CopyToClipboard>
        </div>
      </div>
      <code className={cc([props.className, "px-4"])}>{props.children}</code>
    </div>
  );
}

const Markdown: FC<{ children: string; cursor?: boolean }> = ({
  children,
  cursor,
}) => {
  const content = useMemo(() => reactNodeToString(children), [children]);
  return (
    <div className="relative">
      <CopyToClipboard
        className="cursor-pointer hidden  group-hover:block absolute right-[-50px] top-[-18px] mr-2 mt-2  "
        text={content}
      >
        <Tooltip title="复制">
          <Copy theme="outline" size="16" fill="rgb(156, 163, 175)" />
        </Tooltip>
      </CopyToClipboard>
      <ReactMarkdown
        remarkPlugins={[remarkMath, supersub, remarkBreaks, remarkGfm]}
        rehypePlugins={[
          [rehypeHighlight, { detect: true, ignoreMissing: true }],
        ]}
        className="markdown-body markdown-custom-styles"
        linkTarget="_blank"
        components={{
          a: ({ node, children, ...props }) => {
            if (!props.title) {
              return <a {...props}>{children}</a>;
            }

            return (
              <Tooltip title={props.title}>
                <a {...props} title={undefined}>
                  {children}
                </a>
              </Tooltip>
            );
          },
          code: ({ node, inline, className, children, ...props }) => {
            if (inline) {
              return (
                <code className={className} {...props}>
                  {children}
                </code>
              );
            }
            return <CustomCode className={className}>{children}</CustomCode>;
          },
        }}
      >
        {children + (cursor && size(children) % 2 === 1 ? " ●" : "")}
      </ReactMarkdown>
    </div>
  );
};

export default Markdown;
