// 获取元数据
import Cookies from "js-cookie";
import { TOKEN_KEY } from "../constants/user";
import { useGo } from "./useGo";
import { useUserStore } from "../store/user";
 

export const useAuth = () => {

  const { navigate } = useGo();
  const { setLoginModal } = useUserStore();
 

  const authGo = (path: string) => {
    const token = Cookies.get(TOKEN_KEY);
    if (token) {
      navigate(path);
    } else {
      setLoginModal(true);
    }
  };
  const checkAuth = () => {
    const token = Cookies.get(TOKEN_KEY);
    if (token) {
      return true;
    } 
      
    return false;
    
  }

  return {
    authGo,
    checkAuth,
 
  };
};
