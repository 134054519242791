import objectToGetParams from './utils/objectToGetParams';
import createShareButton from './hocs/createShareButton';

function emailLink(url, { subject, body, separator }) {
  return `mailto:${  objectToGetParams({ subject, body: body ? body + separator + url : url })}`;
}

const EmailShareButton = createShareButton(
  'Email',
  emailLink,
  props => ({
    subject: props.subject,
    body: props.body,
    separator: props.separator || ' ',
  }),
  {
    openShareDialogOnClick: false,
    onClick: (_, link) => {
      window.location.href = link;
    },
  },
);

export default EmailShareButton;
