import { Avatar } from "antd";

import { useUserStore } from "../../../store/user";
import { getAvatrUrl } from "../../../utils/avatar";

const Profile = () => {
  const { user } = useUserStore();

  return (
    <div className="rounded-lg flex p-6 bg-gray-100">
      <Avatar
        className="mr-2 w-[52px] h-[52px] "
        src={getAvatrUrl(user)}
        alt={user?.nickname}
      />
      <div>{user?.nickname}</div>
    </div>
  );
};

export default Profile;
