import assert from './utils/assert';
import objectToGetParams from './utils/objectToGetParams';
import createShareButton from './hocs/createShareButton';

function telegramLink(url, { title }) {
  assert(url, 'telegram.url');

  return (
    `https://telegram.me/share/url${ 
    objectToGetParams({
      url,
      text: title,
    })}`
  );
}

const TelegramShareButton = createShareButton(
  'Telegram',
  telegramLink,
  props => ({
    title: props.title,
  }),
  {
    windowWidth: 550,
    windowHeight: 400,
  },
);

export default TelegramShareButton;
