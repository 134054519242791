import { Button } from "antd";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { map } from "../../../utils/fp";
import Share from "../../../components/Share/Share";
import { categoriesList } from "../constants";
import { useId } from "../../../hooks/useId";
import { useTopGptStore } from "../../../store/topGpt";

const getType = (key: string) => (current: string) =>
  current === key ? "primary" : "default";

function Nav({ current, shareUrl }: any) {
  const id = useId() || "All";
  const { getTopGpt } = useTopGptStore();

  useEffect(() => {
    getTopGpt(id);
  },[id])

  return (
    <div>
      <div className="p-[20px]">
        <Link to="/top">
          <Button
            type={getType("all")(current)}
            className="mb-2 mr-2 rounded-[20px]"
          >
            All
          </Button>
        </Link>
        {map((item: any) => (
          <Link to={`/top/${item?.category}`} key={item}>
            <Button
              type={getType(item)(current)}
              className="mb-2 mr-2 rounded-[20px]"
            >
              {item?.category}({item?.count})
            </Button>
          </Link>
        ))(categoriesList)}
      </div>
      <h1 className=" flex justify-between item-center text-[2rem] leading-[2.35rem] mb-[20px] px-[20px] ">
        {id || 'All'} Custom GPTs
        <Share title={id} shareUrl={shareUrl} />
      </h1>
    </div>
  );
}

export default Nav;
