import React, { Component } from 'react';
import cx from 'classcat';

const defaultChildren = (shareCount) => shareCount;

class SocialMediaShareCount extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = { count: 0, isLoading: false };
  }

  componentDidMount() {
    this._isMounted = true;
    this.updateCount(this.props.url);
  }

  componentDidUpdate(prevProps) {
    if (this.props.url !== prevProps.url) {
      this.updateCount(this.props.url);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  updateCount(url) {
    this.setState({
      isLoading: true,
    });

    this.props.getCount(url, (count) => {
      if (this._isMounted) {
        this.setState({
          count,
          isLoading: false,
        });
      }
    });
  }

  render() {
    const { count, isLoading } = this.state;

    const { children = defaultChildren, className, getCount: _, ...rest } = this.props;

    return (
      <span className={cx('react-share__ShareCount', className)} {...rest}>
        {!isLoading && count !== undefined && children(count)}
      </span>
    );
  }
}

export default function createShareCount(getCount) {
  const ShareCount = (props) => (
    <SocialMediaShareCount getCount={getCount} {...props} />
  );

  ShareCount.displayName = `ShareCount(${getCount.name})`;

  return ShareCount;
}
