import { memo, useCallback } from "react";
import { Avatar } from "antd";
import { map } from "../../../utils/fp";
import { useGptStore } from "../../../store/gpt";
import Create from "./Create";

interface Props {
  active: any;
  setActive: (active: any) => void;
}

const Nav = ({ active = {}, setActive }: Props) => {
  const { gpt } = useGptStore();

  const onSubmitSuccess = useCallback(
    (res: any) => {
      setActive(res);
    },
    [setActive]
  );

  return (
    <div className="w-[300px]  h-full overflow-y-auto border-r ">
      <div className="  border-b  p-4  font-bold cursor-pointer">
        <Create key={active.id} onSubmitSuccess={onSubmitSuccess}>
          <div className="w-full  rounded-xl item-center  justify-center bg-[#f3f3f3] py-4 flex   shadow-[0_1px_7px_0px_rgba(0,0,0,0.06)]   ">
            <div>
              <span className="text-brand-green"> Submit GPT</span>
            </div>
          </div>
        </Create>
      </div>
      {map((item: any = {}) => (
        <div
          key={item.id}
          onClick={() => {
            setActive(item);
          }}
          className={`${
            item.id === active.id && "bg-gray-100"
          } flex border-b border-gray-100 px-4  items-center py-4 cursor-pointer`}
        >
          <Avatar
            src={item?.profile_picture_url}
            className="mr-1 w-[36px] h-[36px]"
          />

          <div className="flex items-center">
            <div className="">{item?.name}</div>
          </div>
        </div>
      ))(gpt)}
    </div>
  );
};

export default memo(Nav);
