import { memo, useState, useEffect } from "react";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import { isEmpty, first } from "../../utils/fp";

import { useGptStore } from "../../store/gpt";
import Cover from "./components/EditCover";
 
import Nav from "./components/Nav";
import Description from "./components/EditDescription";
import { useRouterGuards } from "../../hooks/useRouterGuards";
import { useId } from "../../hooks/useId";

const Gpt = () => {
  useRouterGuards();
  const { gpt } = useGptStore();

  const [active, setActive] = useState<any>({});
  const id = useId();

  useEffect(() => {
    if (gpt && isEmpty(active)) {
      if (!id) {
        setActive(first(gpt));
      }

      const find = gpt.find((item: any) => item.id === id);
      if (find) {
        setActive(find);
      }
    }
  }, [gpt, active, id]);

  useDocumentTitle("Submit GPT");

  return (
    <div className="flex w-full h-full">
      <Nav active={active} setActive={setActive} />

      <div className="flex-1  bg-white  relative overflow-y-auto  px-[72px] ">
        <Cover gpt={active} setActive={setActive} />
        <Description gpt={active} />
      </div>
    </div>
  );
};

export default memo(Gpt);
