import React, { useCallback } from "react";
import { Avatar } from "antd";
import { useLocation, Link } from "react-router-dom";
import { useGo } from "../../hooks/useGo";
import { useAuth } from "../../hooks/useAuth";
import { useLogin } from "../../hooks/useLogin";
import { useUserStore } from "../../store/user";
import { getAvatrUrl } from "../../utils/avatar";
import LoginButton from "./LoginModel";

// import Login from "./Login";

const Bar = () => {
  useLogin();
  const { navigate } = useGo();
  const location = useLocation();
  const currentPath = location.pathname;
  const { user } = useUserStore();
  const { authGo, checkAuth } = useAuth();

  const go = useCallback(
    (path: string) => () => {
      navigate(path);
    },
    [navigate]
  );

  const getBgClass = useCallback(
    (path: string) => {
      if (path === currentPath) {
        return " text-brand-green font-medium";
      }
      return "";
    },
    [currentPath]
  );

  return (
    <div className="flex  justify-between items-center  fixed left-0 right-0   z-10 bg-white   md:px-[32px]   h-[64px] shadow-[0_1px_7px_0px_rgba(0,0,0,0.06)]">
      <div className="flex items-center ">
        <div
          onClick={go("/discover")}
          className="items-center cursor-pointer hidden md:flex "
        >
          <div className="font-logo text-[26px] text-brand-green">
            GPT Store
          </div>
        </div>

        <Link
          to="/top"
          className={`${getBgClass(
            "/top"
          )} ml-2 md:ml-6  flex flex-col items-center   cursor-pointer`}
        >
          <div className="text-[14px] md:text-[16px] ">Top Chart</div>
        </Link>
      </div>
      <div className="flex items-center">
      <div
          onClick={() => authGo("/store")}
          className={`${getBgClass(
            "/store"
          )} flex flex-col items-center   cursor-pointer mr-4 md:mr-6`}
        >
          <div className="text-[14px] md:text-[16px] ">Store</div>
        </div>
        <div
          onClick={() => authGo("/profile")}
          className={`${getBgClass(
            "/profile"
          )} flex flex-col items-center   cursor-pointer mr-4 md:mr-6`}
        >
          {checkAuth() ? (
            <Avatar className=" w-[32px] h-[32px]" src={getAvatrUrl(user)} />
          ) : (
            <div className="text-[14px] md:text-[16px] ">Log in</div>
          )}
        </div>
        {!checkAuth() && (
          <Link
            to="/sign-up"
            className={`${getBgClass(
              "/sign-up"
            )}  flex-col items-center hidden md:flex   cursor-pointer `}
          >
            <div className="text-[16px] ">Sign up</div>
          </Link>
        )}
        <LoginButton />
      </div>
    </div>
  );
};

export default Bar;
