import { useMemo } from "react";
import { useTopGptStore } from "../../../store/topGpt";
import { useId } from "../../../hooks/useId";

export const useGptMap = () => {
  const { gptMap } = useTopGptStore();
  const id = useId() || "All";
  const gpts = useMemo(() => gptMap[id], [gptMap, id]);

  return {
    gpts,
  };
};
