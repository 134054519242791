// 向左滑动按钮
import { useContext } from "react";
import { LeftOutlined } from "@ant-design/icons";
import { VisibilityContext } from "react-horizontal-scrolling-menu";


function Left() {
  const { isFirstItemVisible, scrollPrev } = useContext(VisibilityContext);

  return (
    <div
      className={`${
        isFirstItemVisible ? "hidden" : "block"
      } flex items-center justify-center  rounded-[26px] bg-white absolute left-[-20px] top-[30px] z-[10] w-[52px] h-[52px] cover-shadow `}
      onClick={() => scrollPrev()}
    >
      <LeftOutlined className="text-gray-600" />
    </div>
  );
}

export default Left