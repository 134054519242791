import assert from './utils/assert';
import objectToGetParams from './utils/objectToGetParams';
import createShareButton from './hocs/createShareButton';

function viberLink(url, { title, separator }) {
  assert(url, 'viber.url');
  return (
    `viber://forward${ 
    objectToGetParams({
      text: title ? title + separator + url : url,
    })}`
  );
}

const ViberShareButton = createShareButton(
  'viber',
  viberLink,
  props => ({
    title: props.title,
    separator: props.separator || ' ',
  }),
  {
    windowWidth: 660,
    windowHeight: 460,
  },
);

export default ViberShareButton;
