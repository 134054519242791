import Markdown from "../../components/Markdown/index";

function Terms() {
  return (
    <div className="flex justify-center p-4">
      <div className="w-[900px]">
        <Markdown>{`# Terms of Service

## 1. Introduction
Welcome to GPT-Store. This User Agreement ("Agreement") sets forth the rights and obligations of using GPT-Store and its related services. Please read this Agreement carefully before using our website and services.

## 2. Acceptance of Terms
By accessing and using GPT-Store, you acknowledge that you have read, understood, and agree to be bound by this Agreement.

## 3. Description of Service
GPT-Store offers a variety of GPT-related online services, including but not limited to AI application development, data analysis, and community interaction.

## 4. User Registration

Users need to register an account to use certain services.
You agree to provide true, accurate registration information and to maintain its accuracy.
## 5. User Conduct

You agree not to engage in any illegal or unauthorized activities using GPT-Store.
You are fully responsible for all activities under your account.
## 6. Copyright of Content

All content on GPT-Store, including texts, images, and code, is protected by copyright and other intellectual property laws.
## 7. Privacy Policy

Your personal information will be processed in accordance with our privacy policy.
## 8. Limitation of Liability

GPT-Store is not liable for any direct or indirect damages arising from the use or inability to use the services.
## 9. Amendment of Agreement

GPT-Store reserves the right to modify the terms of this Agreement at any time.
## 10. Applicable Law and Jurisdiction

This Agreement shall be interpreted and governed by the relevant laws, and subject to the jurisdiction of the courts in the location.
## 11. Contact Information

For any questions regarding this Agreement, please contact us.

`}</Markdown>
      </div>
    </div>
  );
}

export default Terms;
