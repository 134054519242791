import React, { useCallback, useEffect, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Upload, Avatar } from "antd";
import type { UploadChangeParam } from "antd/es/upload";
import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import AxiosService from "../../api/AxiosService";
import { noEmpty } from "../../utils/fp";
import EditIcon from "./EditIcon"

const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

//
interface UploadAvatarProps {
  onUpload: (url: string) => void;
  avatar?: string;
}

const UploadAvatar = ({ onUpload, avatar }: UploadAvatarProps) => {
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>();
  const [message, setMessage] = useState<string>("");

  useEffect(() => {
    if (noEmpty(avatar)) {
      setImageUrl(avatar);
    }
  }, [avatar]);

  const handleChange: UploadProps["onChange"] = useCallback(
    (info: UploadChangeParam<UploadFile>) => {
      if (info.file.status === "uploading") {
        setLoading(true);
        return;
      }
      if (info.file.status === "done") {
        // Get this url from response in real world.
        onUpload(info.file.response.url);
        getBase64(info.file.originFileObj as RcFile, (url) => {
          setLoading(false);
          setImageUrl(url);
        });
      }
    },
    [onUpload],
  );

  const beforeUpload = useCallback((file: RcFile) => {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/gif" ||
      file.type === "image/jpg" ||
      file.type === "image/webp";
    const isLt1M = file.size / 1024 / 1024 < 1;
    if (!isJpgOrPng) {
      setMessage("You can only upload files in image format.");
    } else if (!isLt1M) {
      setMessage("Images must not exceed 1MB in size.");
    } else {
      setMessage("");
    }

    return isJpgOrPng && isLt1M;
  }, []);

  const uploadButton = (
    <div className="flex flex-col items-center ">
      {loading ? <LoadingOutlined /> : <EditIcon  className="svg-icon"  width={48} height={48} /> }
      <div className="mt-[10px] text-[16px] label">Choose photo</div>
    </div>
  );

  return (
    <div>
      <Upload
        name="avatar"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        action={AxiosService.getUplodUrl()}
        beforeUpload={beforeUpload}
        onChange={handleChange}
      >
        {imageUrl ? (
          <Avatar
            className="w-full h-full"
            src={imageUrl}
            alt="avatar"
            shape="square"
          />
        ) : (
          uploadButton
        )}
      </Upload>
      {message && <p className="text-red-500 text-xs mt-2">{message}</p>}
    </div>
  );
};

export default UploadAvatar;
