import React from "react";

const IframeEmbed = ({ src }: any) => (
  <div className="border rounded-xl ">
    <iframe
      src={src}
      width="100%"
      height="600px"
      style={{ border: "none" }}
      title="Embedded Page"
    />
  </div>
);

export default IframeEmbed;
