import objectToGetParams from './utils/objectToGetParams';
import createShareButton from './hocs/createShareButton';

function facebookMessengerLink(url, { appId, redirectUri, to }) {
  return (
    `https://www.facebook.com/dialog/send${ 
    objectToGetParams({
      link: url,
      redirect_uri: redirectUri || url,
      app_id: appId,
      to,
    })}`
  );
}

const FacebookMessengerShareButton = createShareButton(
  'facebookmessenger',
  facebookMessengerLink,
  props => ({
    appId: props.appId,
    redirectUri: props.redirectUri,
    to: props.to,
  }),
  {
    windowWidth: 1000,
    windowHeight: 820,
  },
);

export default FacebookMessengerShareButton;
