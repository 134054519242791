// store.ts
import { create } from "zustand";
import AxiosService from "../api/AxiosService";
// import LocalStorageService from "../service/LocalStorageService";

// const TOP_GPT = "TOP_GPT";

export type Store = {
  gptMap: any;

  getTopGpt: (category: string) => void;
};

export const useTopGptStore = create<Store>((set, get) => ({
  gptMap: {},

  getTopGpt: (category: string) =>
    AxiosService.get(`/api/get-top-gpt?category=${category}`).then(
      (res: any) => {
        // LocalStorageService.setItem(TOP_GPT, res);
        const oldMap = get().gptMap;
        const gptMap = { ...oldMap, [category]: res };
        set({
          gptMap,
        });
      }
    ),
}));
