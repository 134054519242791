// store.ts
import { create } from "zustand";
import AxiosService from "../api/AxiosService";
import LocalStorageService from "../service/LocalStorageService";
import { normalize, omit, filter } from "../utils/fp";

const GPT = "GPT";

export type Store = {
  gpt: any[];
  gptMap: any;
  getMyGpt: () => void;
  newGpt: (meta: any) => any;
  refreshGpt: (gpt: any) => void;
  removeGpt: (id: string) => void;
};

export const useGptStore = create<Store>((set, get) => ({
  gpt: LocalStorageService.getItem(GPT),
  gptMap: normalize(LocalStorageService.getItem(GPT)),
  getMyGpt: () =>
    AxiosService.get("/api/get-my-gpts").then((res: any) => {
      // LocalStorageService.setItem(GPT, res);
      set({
        gpt: res,
        gptMap: normalize(res),
      });
    }),
  newGpt: (meta: any) =>
    AxiosService.post("/api/new-gpt", meta).then((gpt: any) => {
      const { gpt: oldGpt, gptMap: oldGptMap } = get();
      const newGpt = [...oldGpt];
      const newGptMap = { ...oldGptMap };
      const index = newGpt.findIndex((item) => item.id === gpt.id);
    if (index !== -1) {
      newGpt[index] = gpt;
      newGptMap[gpt.id] = gpt;
    } else {
      newGpt.unshift(gpt);
      newGptMap[gpt.id] = gpt;
    }
      newGptMap[gpt.id] = gpt;
      newGpt.unshift(gpt);
      // LocalStorageService.setItem(GPT, gpt);
      set({
        gpt: newGpt,
        gptMap: newGptMap,
      });
      return gpt;
    }),
  refreshGpt: (gpt) => {
    /* 将传入的gpt更新到gpt和gptMap 中 */
    const { gpt: oldgpt, gptMap: oldgptMap } = get();
    const newGpt = [...oldgpt];
    const newGptMap = { ...oldgptMap };
    const index = newGpt.findIndex((item) => item.id === gpt.id);
    if (index !== -1) {
      newGpt[index] = gpt;
      newGptMap[gpt.id] = gpt;
    } else {
      newGpt.unshift(gpt);
      newGptMap[gpt.id] = gpt;
    }
    set({ gpt: newGpt, gptMap: newGptMap });
  },
  removeGpt: (id: string) => {
    /* 将传入的gpt更新到gpt和gptMap 中 */
    const { gpt, gptMap } = get();

    set({
      gpt: filter((item: any) => item.id !== id)(gpt),
      gptMap: omit(id)(gptMap),
    });
  },
}));
